// Common
import { useSelector } from 'react-redux';

import { useEffect, useState } from 'react';
import { showFile } from '../../services/user';

// Components
import { Box, ThemeProvider, Typography } from '@material-ui/core';

// Images
import Fb from '../../images/facebookIcon.svg';
import Tw from '../../images/twitterIcon.svg';
import Yt from '../../images/youtubeIcon.svg';
import Ln from '../../images/linkedinIcon.svg';

// Styles
import { email, name } from './customStyles';
import './avatarCard.scss';
import { getAbbreviation } from './abbreviation';

export default function AvatarCard() {
  const user = useSelector((state) => state.user.data);
  const [Img, setImg] = useState(user?.picture);

  useEffect(() => {
    if (user?.picture !== null && user?.picture !== undefined) {
      showFile(user?.picture).then((res) => setImg(res.data));
    }
  }, [user?.picture]);
  return (
    <>
      <Box
        width="250px"
        boxShadow="0 0 15px 0 rgba(0, 0, 0, 0.07)"
        height="334px"
        display="flex"
        borderRadius="8px"
        flexDirection="column"
        alignItems="center"
        textAlign="center"
      >
        <Box
          borderRadius="50%"
          bgcolor="#f2f2f2"
          width="110px"
          height="110px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          marginTop="32px"
        >
          {Img === null || Img === undefined ? (
            <Typography variant="h3">{getAbbreviation(user?.name)}</Typography>
          ) : (
            <img
              alt=""
              src={Img}
              style={{
                borderRadius: '50%',
                width: '110px',
                height: '110px',
                objectFit: 'cover',
              }}
            />
          )}
        </Box>

        <ThemeProvider theme={name}>
          <Box
            className="profile_tooltip"
            width="80%"
            position="relative"
            display="inline-block"
          >
            {user?.name.length > 20 ? (
              <>
                <Typography variant="h1">
                  {user?.name.slice(0, 17) + '...'}
                </Typography>
                <span className="tooltiptext">{user?.name}</span>
              </>
            ) : (
              <Typography variant="h1">{user?.name}</Typography>
            )}
          </Box>
        </ThemeProvider>
        <ThemeProvider theme={email}>
          <Box
            className="profile_tooltip"
            width="80%"
            position="relative"
            display="inline-block"
          >
            {user?.email.length > 20 ? (
              <>
                <Typography variant="h1" color="secondary">
                  {user?.email.slice(0, 17) + '...'}
                </Typography>
                <span className="tooltiptext">{user?.email}</span>
              </>
            ) : (
              <Typography variant="h1" color="secondary">
                {user?.email}
              </Typography>
            )}
          </Box>
        </ThemeProvider>
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="25px"
          mt="24px"
        >
          <a href={user.links?.facebook} target="_blank" rel="noreferrer">
            <img width="20px" height="20px" alt="" src={Fb} />
          </a>
          <a href={user.links?.twitter} target="_blank" rel="noreferrer">
            <img
              style={{ marginLeft: '20px' }}
              width="20px"
              height="20px"
              alt=""
              src={Tw}
            />
          </a>
          <a href={user.links?.youtube} target="_blank" rel="noreferrer">
            <img
              style={{ marginLeft: '20px' }}
              width="20px"
              height="20px"
              alt=""
              src={Yt}
            />
          </a>
          <a href={user.links?.linkedin} rel="noreferrer" target="_blank">
            <img
              style={{ marginLeft: '20px' }}
              width="20px"
              height="20px"
              alt=""
              src={Ln}
            />
          </a>
        </Box>
      </Box>
    </>
  );
}
