// Common
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Link as routerLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Components
import SessionContainer from './session_container';
import { Grid, Typography, Button, Link, Box } from '@material-ui/core';
import { Input } from '../common/input/index';

//Action
import { loginRequest } from '../../actions/user';

export default function LogIn() {
  const [user, setUser] = useState({
    email: '',
    password: '',
  });
  const [activeButton, setActiveButton] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    let active = true;
    for (let i in user) {
      if (!!user[i] === false) {
        active = false;
        break;
      }
    }
    setActiveButton(active);
  }, [user]);

  const handleChange = (event) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = useCallback(() => {
    dispatch(loginRequest(user));
  }, [dispatch, user]);

  const data = useMemo(() => {
    return [
      {
        label: 'Email',
        value: user.email,
        name: 'email',
        type: 'text',
      },
      {
        label: 'Password',
        value: user.password,
        name: 'password',
        type: 'password',
      },
    ];
  }, [user]);

  return (
    <SessionContainer>
      <Grid item>
        <Typography variant="h1">Log in page</Typography>
      </Grid>
      {data.map((item) => (
        <Grid key={item.label} item>
          <Input
            label={item.label}
            name={item.name}
            type={item.type}
            value={item.value}
            onChange={handleChange}
          />
        </Grid>
      ))}
      <Grid item>
        <Box mb="32px">
          <Link underline="none" component={routerLink} to="/password_forgot">
            Forgot password
          </Link>
        </Box>
        <Button
          size="large"
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          disabled={!activeButton}
        >
          Log in
        </Button>
        <Box mt="16px">
          <Typography variant="subtitle1">
            {'Don’t have an account? '}
            <Link underline="none" component={routerLink} to="/sign_up">
              Sign up
            </Link>
          </Typography>
        </Box>
      </Grid>
    </SessionContainer>
  );
}
