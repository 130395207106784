import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getAbbreviation } from '../profile/abbreviation';
import moment from 'moment';

import './index.scss';

export default function ChatItem({ chat, active }) {
  const [user, setUser] = useState(null);
  const profile_id = useSelector((state) => state.user.data._id);
  const history = useHistory();

  useEffect(() => {
    setUser(chat.users.find((item) => item._id !== profile_id));
  }, [profile_id, chat]);

  const openChat = () => {
    history.push(`/messages/${chat._id}`);
  };

  return (
    user && (
      <div
        className={'chat_item' + (active ? ' active' : '')}
        onClick={() => openChat()}
      >
        {user.picture ? (
          <img className="avatar" src={user.picture} alt="avatar" />
        ) : (
          <div className="avatar">{getAbbreviation(user.name)}</div>
        )}

        <div className="chat_info">
          <div className="chat_contact_name">{user.name}</div>
          <div className="chat_last_message">
            {chat.latestMessage.message.content}
          </div>
          <span className="chat_project_name">{chat.projectName}</span>
        </div>
        <div className="last_message_time">
          {moment(chat.updatedAt).format('LT')}
        </div>
      </div>
    )
  );
}
