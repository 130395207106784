import React from 'react';
import { Paper, Box, Grid } from '@material-ui/core';

import Logo from '../../images/logo.png';
import './index.scss';

export default function SessionContainer({ children }) {
  return (
    <div className="session_container">
      <img className="logo" src={Logo} alt="logo" />
      <Paper>
        <Box width={366} py="62px" px="177px">
          <Grid
            container
            alignContent="center"
            direction="column"
            justify="flex-start"
            spacing={2}
          >
            {children}
          </Grid>
        </Box>
      </Paper>
    </div>
  );
}
