// Common
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Action
import {
  handleChangeOwnerCompany,
  handleChangeOwnerTeam,
  removeImage,
  uploadTeamMemberAvatar,
} from '../../../actions/projects';

// Components
import { Typography, Box, Grid, Button } from '@material-ui/core';
import { Input } from '../../common/input';
import { TooltipComponent } from '../../common/tooltip/index';
import { ReactComponent as IconAdd } from '../../../images/icons_Plus.svg';
import { ReactComponent as IconRemove } from '../../../images/icons_delete_circle.svg';
import ImageUploader from '../../common/imageUploader';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import CountryCode from '../../common/countryCode';

export default function StepOne() {
  const imageUploading = useSelector((state) => state.projects.uploadingFile);
  const ownerCompany = useSelector(
    (state) => state.projects.selected.ownerCompany,
  );
  const uploadingFileUrl = useSelector(
    (state) => state.projects.uploadingFileUrl,
  );
  const projectType = useSelector((state) => state.projects.selected.type);
  const ownerTeam = useSelector((state) => state.projects.selected.team);
  const { id } = useParams();

  const [disabled, setDisabled] = useState(false);
  const [team, setTeam] = useState(ownerTeam);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    dispatch(
      handleChangeOwnerCompany({ [event.target.name]: event.target.value }),
    );
  };

  useEffect(() => {
    if (uploadingFileUrl?.url?.length > 0) {
      setTeam(
        team.map((element, index) =>
          index === uploadingFileUrl.index
            ? { ...element, image: uploadingFileUrl.url }
            : element,
        ),
      );
    }
  }, [uploadingFileUrl, team]);

  useEffect(() => {
    if (team.length > 4) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [team]);

  const data = useMemo(() => {
    return [
      {
        label: 'Company name',
        value: ownerCompany.name,
        name: 'name',
        type: 'text',
        validation: true,
      },
      {
        label: 'Street address',
        value: ownerCompany.address,
        name: 'address',
        type: 'text',
        validation: true,
      },
      {
        label: 'Zip',
        value: ownerCompany.zip,
        name: 'zip',
        type: 'text',
        validation: true,
      },
      {
        label: 'City',
        value: ownerCompany.city,
        name: 'city',
        type: 'text',
        validation: true,
      },
      {
        label: 'Email',
        value: ownerCompany.email,
        name: 'email',
        type: 'text',
        validation: true,
      },
      {
        label: 'Company website',
        value: ownerCompany.website || '',
        name: 'website',
        type: 'text',
        validation: true,
      },
      {
        label: 'Mobile',
        value: ownerCompany.mobile,
        name: 'mobile',
        type: 'text',
        validation: true,
      },
    ];
  }, [ownerCompany]);

  const addPerson = () => {
    setTeam([...team, { title: '', name: '', linkedInLink: '', image: '' }]);
  };

  const removeItem = (id, item, index) => {
    if (team[index]?.image?.length > 0) {
      onDestroyImg(id, item, index);
    }
    setTeam(team.filter((e, i) => i !== index));
  };

  const handleChangePerson = (value, name, id) => {
    setTeam(
      team.map((element, index) =>
        index === id ? { ...element, [name]: value } : element,
      ),
    );
  };

  const onDestroyImg = (id, item, index) => {
    dispatch(
      removeImage(
        id,
        [
          item.image.replace(
            'https://moneyflow-back.s3.us-east-2.amazonaws.com/',
            '',
          ),
        ],
        'team-member',
      ),
      handleChangePerson('', 'image', index),
    );
  };

  useEffect(() => {
    if (team.length > 0) {
      if (
        team.filter(
          (e) =>
            e?.title?.length > 0 || e?.name?.length || e?.image?.length > 0,
        )
      ) {
        dispatch(handleChangeOwnerTeam(team));
      }
    }
  }, [dispatch, team]);

  return (
    <div>
      <Typography variant="h2">Basic information</Typography>
      <Box mt="10px">
        <Typography variant="subtitle2">
          Add your company address and contact information.
          <Typography>
            This is neccessary if you want investors to get in touch with you.
          </Typography>
        </Typography>
      </Box>
      <Box mt="48px" maxWidth="50%">
        <Typography variant="h4">Contact information</Typography>
        <br />
        <Grid container>
          {data.map((item, index) => (
            <React.Fragment key={index}>
              {item.name === 'mobile' && (
                <Grid item xs={6}>
                  <Box mr="40px" mt="20px">
                    <CountryCode
                      onChange={handleChange}
                      value={ownerCompany.countryCode}
                    />
                  </Box>
                </Grid>
              )}
              <Grid item xs={6}>
                <Box mr="40px" mt="20px">
                  <Input
                    label={item.label}
                    name={item.name}
                    type={item.type}
                    value={item.value}
                    onChange={handleChange}
                    validation={item.validation}
                  />
                </Box>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Box>
      {projectType === 'company' && (
        <Box mr="40px" mt="40px" maxWidth="50%">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h4">Vat number</Typography>
            <TooltipComponent text="Company registration number" />
          </div>
          <br />
          <Grid container>
            <Grid item xs={6}>
              <Box mr="40px">
                <Input
                  label="Vat Number"
                  name="vatNumber"
                  type="text"
                  value={ownerCompany.vatNumber}
                  onChange={handleChange}
                  validation={projectType === 'company'}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
      <Box mt="40px">
        <Box mb="20px">
          <Typography variant="h4">Team</Typography>
        </Box>
        {disabled ? (
          <Typography variant="h4">Max 5 added person.</Typography>
        ) : (
          <Button
            onClick={() => addPerson()}
            style={{
              textTransform: 'none',
              fontSize: '16px',
              fontFamily: 'SFProDisplay-Medium',
              width: '130px',
              height: '30px',
              background: '#62a4cd',
              color: '#ffffff',
              transition: 'all 1s ease-out',
              '&:hover': {
                background: '#62a4cd',
              },
              '&:disabled': {
                background: '#e1f0fa',
                color: '#ffffff',
              },
            }}
          >
            <IconAdd />
            Add person
          </Button>
        )}
        <Box mt="30px" maxWidth="95%">
          {team.length > 0 ? (
            team.map((item, index) => (
              <Box key={index}>
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={2}>
                    <ImageUploader
                      isTeamImg
                      name="team-img"
                      onChange={(e) => {
                        dispatch(
                          uploadTeamMemberAvatar(e.target.files[0], index),
                        );
                      }}
                      imageUrl={item.image}
                      onDestroy={() => onDestroyImg(id, item, index)}
                      imageUploading={imageUploading}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Input
                      label="Name"
                      name="name"
                      type="text"
                      value={item.name}
                      onChange={(e) =>
                        handleChangePerson(e.target.value, e.target.name, index)
                      }
                      validation={false}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Input
                      label="Title"
                      name="title"
                      type="text"
                      value={item.title}
                      onChange={(e) =>
                        handleChangePerson(e.target.value, e.target.name, index)
                      }
                      validation={false}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Input
                      label="LinkedIn"
                      name="linkedInLink"
                      type="text"
                      value={item.linkedInLink}
                      onChange={(e) =>
                        handleChangePerson(e.target.value, e.target.name, index)
                      }
                      validation={false}
                    />
                  </Grid>

                  <Grid item xs={2} sm={1}>
                    <IconRemove
                      onClick={() => removeItem(id, item, index)}
                      width="110%"
                      height="110%"
                      style={{
                        cursor: 'pointer',
                        maxHeight: '50px',
                        maxWidth: '50px',
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
            ))
          ) : (
            <></>
          )}
        </Box>
      </Box>
    </div>
  );
}
