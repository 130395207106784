// Common
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';

// Components
import { Typography, Box, Grid } from '@material-ui/core';
import { Input } from '../common/input';
import NextBackButtons from './nextBackButtons';

// Actions
import {
  handleChangeInvestmenNeeds,
  updateProject,
  handleChangeProject,
} from '../../actions/projects';

// Styles
import './index.scss';
import { CheckBox } from '../common/checkbox';

const invTypes = [
  'Seed/Angel',
  'Venture Capital',
  'Corporate Investor',
  'Investment Fund',
];

const invStages = ['Idea/Prototype', 'Early Growth', 'Late Growth', 'Mature'];

export default function Investments() {
  const { number, sharesNumber, price, budget } = useSelector(
    (state) => state.projects.selected.investmentNeeds,
  );
  const { stages, investmentTypes } = useSelector(
    (state) => state.projects.selected,
  );
  const project = useSelector((state) => state.projects.selected);
  const errors = useSelector((state) => state.errors);
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const handleChange = (event) => {
    dispatch(
      handleChangeInvestmenNeeds({ [event.target.name]: event.target.value }),
    );
  };

  const onClickNext = () => {
    dispatch(updateProject(project, `/project/bisiness_model/${project._id}`));
  };

  const handleChangeTypes = (event) => {
    let _types = investmentTypes.slice();
    if (_types.find((item) => item === event.target.name)) {
      _types.splice(
        _types.findIndex((item) => item === event.target.name),
        2,
      );
    } else {
      _types.push(event.target.name);
    }
    dispatch(handleChangeProject({ investmentTypes: _types }));
  };

  const handleChangeStage = (event) => {
    dispatch(handleChangeProject({ stages: event.target.name }));
  };

  const getNumberOfInvestors = () => {
    // eslint-disable-next-line
    return number !== null ? (number == 0 ? '0' : +number) : '';
  };

  return (
    <div className="project_form_container">
      <Typography variant="h2">Investment needs</Typography>
      <Box mt="10px">
        <Typography variant="subtitle2">
          Please, tell us about your capital need for your company or project,
          and how many percent of your company you are willing to sell to an
          investor.
        </Typography>
      </Box>
      <Box mt="50px" mb="30px">
        <Grid container spacing={7}>
          <Grid item xs={4} md={5}>
            <Typography variant="h4">
              How many investors have invested in your company?
            </Typography>
            <br />
            <br />
            <Input
              label="Select number of investors"
              name="number"
              type="tel"
              value={getNumberOfInvestors()}
              onChange={handleChange}
              validation={true}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h4">
              How many shares are you willing to sell?
            </Typography>
            <br />
            <br />
            <Input
              label="Shares are you willing to sell"
              name="sharesNumber"
              type="text"
              value={+sharesNumber || ''}
              onChange={handleChange}
              validation={true}
            />
          </Grid>
        </Grid>
      </Box>
      <div className="underline_gray" />
      <Box mt="50px">
        <Grid container spacing={7}>
          <Grid item xs={4}>
            <Typography variant="h4">Price per share?</Typography>
            <br />
            <br />
            <Input
              label="Example $3"
              name="price"
              type="text"
              value={price || ''}
              onChange={handleChange}
              validation={true}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h4">Capital need</Typography>
            <br />
            <br />
            <Input
              label="Project budget"
              name="budget"
              type="text"
              value={budget || ''}
              onChange={handleChange}
              validation={true}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt="50px">
        <Box mb="30px">
          <div className="underline_gray" />
        </Box>
        <Grid container spacing={7}>
          <Grid item>
            <Typography variant="h4">Investment types</Typography>
            <Grid item>
              <Box mt="40px" display="flex">
                {invTypes.map((el, index) => (
                  <Box ml="30px" key={index}>
                    <CheckBox
                      value={!!investmentTypes.find((item) => item === el)}
                      label={el}
                      name={el}
                      onChange={handleChangeTypes}
                    />
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box mt="50px" mb="48px">
        <Box mb="30px">
          <div className="underline_gray" />
        </Box>
        <Grid container spacing={7}>
          <Grid item>
            <Typography variant="h4">Investment stages</Typography>
            <Grid item>
              <Box mt="40px" display="flex">
                {invStages.map((el, index) => (
                  <Box ml="30px" key={index}>
                    <CheckBox
                      value={stages === el}
                      label={el}
                      name={el}
                      onChange={handleChangeStage}
                    />
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <NextBackButtons
        onClickNext={() => onClickNext()}
        onClickBack={() => history.push(`/project/docs/${id}`)}
        disabledNext={
          !(number && sharesNumber && price && budget && !errors.length)
        }
      />
    </div>
  );
}
