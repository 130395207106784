// Common
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useHistory } from 'react-router-dom';
import { LinkStyle } from '../../session/password_forgot/password_forgot_modal';

// Component
import { Button, MenuItem, Menu, ListItemIcon } from '@material-ui/core';

// Images
import ArrowDown from '../../../images/icons_arrow_down.svg';
import ProfileIcon from '../../../images/profile_icon.svg';
import QuestionIcon from '../../../images/question_icon.svg';
import LogOutIcon from '../../../images/log_out_icon.svg';

// Style
import './index.scss';
const useStyles = makeStyles({
  button: {
    backgroundColor: '#fff',
    border: 'none',
    fontSize: '16px',
    lineHeight: 1.5,
    color: '#393939',
    fontFamily: 'SFProDisplay-Medium',
  },
  rootMenu: {
    '& .MuiPaper-root': {
      width: '201px',
      boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.07)',
      borderRadius: '8px',
      padding: '8px 0  ',
    },
  },
  menuItem: {
    height: '48px',
    fontSize: '14px',
    fontFamily: 'SFProDisplay-Medium',
    color: '#393939',
    lineHeight: 1.29,
    '& .MuiListItemIcon-root': {
      minWidth: '36px',
    },
  },
});

export function ProfileMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const user = useSelector((state) => state.user.data);
  const classes = useStyles();
  const history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = () => {
    localStorage.setItem('session_token', '');
    history.push('/login');
  };

  return (
    <div>
      <Button
        className={classes.button}
        endIcon={<img src={ArrowDown} alt="arrow_down" />}
        onClick={handleClick}
      >
        {user.name}
      </Button>
      <Menu
        className={classes.rootMenu}
        elevation={0}
        getContentAnchorEl={null}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Link to="/profile" style={LinkStyle}>
          <MenuItem className={classes.menuItem}>
            <ListItemIcon>
              <img src={ProfileIcon} alt="profile_icon" />
            </ListItemIcon>
            Profile
          </MenuItem>
        </Link>
        <MenuItem className={classes.menuItem}>
          <a className="support-link" href="mailto:support@moneyflow.no">
            <ListItemIcon>
              <img src={QuestionIcon} alt="question_icon" />
            </ListItemIcon>
            Support
          </a>
        </MenuItem>
        <MenuItem className={classes.menuItem} onClick={() => logOut()}>
          <ListItemIcon>
            <img src={LogOutIcon} alt="log_out_icon" />
          </ListItemIcon>
          Log out
        </MenuItem>
      </Menu>
    </div>
  );
}
