// Common
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';

// Action
import {
  handleChangeProject,
  createProject,
  updateProject,
} from '../../actions/projects';

// Components
import { Typography, Box, Grid } from '@material-ui/core';
import { Input } from '../common/input/index';
import { Multiline } from '../common/input/multiline';
import { TooltipComponent } from '../common/tooltip/index';
import NextBackButtons from './nextBackButtons';

// Styles
import './index.scss';

export default function Description() {
  // const [showDescriptionErrors, setShowErrors] = useState(false)
  const { title, shortDescription, longDescription } = useSelector(
    (state) => state.projects.selected,
  );
  const project = useSelector((state) => state.projects.selected);
  const errors = useSelector((state) => state.errors);
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const handleChange = (event) => {
    dispatch(handleChangeProject({ [event.target.name]: event.target.value }));
  };

  const onClickNext = () => {
    if (id !== 'new') {
      dispatch(updateProject(project, `/project/video/${project._id}`));
    } else {
      dispatch(createProject(project, `/project/video/`));
    }
  };

  return (
    <div className="project_form_container">
      <Typography variant="h2">Promotion</Typography>
      <Box mt="10px">
        <Typography variant="subtitle2">
          The information on this page will be visible to all users. Information
          you don't want to show in the open should be added only in the
          restricted information area.
        </Typography>
      </Box>
      <Box mt="48px" maxWidth="450px">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h3">Project title</Typography>
          <TooltipComponent text="This is the name of your app or your company / brand name. You can also change the title later." />
        </div>
        <br />
        <Input
          label={'Project title'}
          name={'title'}
          type="text"
          value={title}
          onChange={handleChange}
          validation={true}
        />
      </Box>
      <Box mt="50px">
        <Typography variant="h3">Descriptions</Typography>
      </Box>
      <Box maxWidth="920px" mt="20px" minHeight="300px">
        <Grid container spacing={5} alignItems="flex-start">
          <Grid item xs={6}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h4">Short Description</Typography>
              <TooltipComponent text="Describe your project with only the necessary information. This is a teaser for the investor. Use the long description for more detailed information about your project." />
            </div>
            <br />
            <Multiline
              label={'Short Description'}
              name={'shortDescription'}
              type="text"
              value={shortDescription}
              onChange={handleChange}
              validation={true}
              formHelperText="Min 200 characters"
            />
          </Grid>
          <Grid item xs={6}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h4">Long Description</Typography>
              <TooltipComponent text="Describe your project as well as you can" />
            </div>
            <br />
            <Multiline
              label={'Long Description'}
              name={'longDescription'}
              type="text"
              value={longDescription}
              onChange={handleChange}
              validation={true}
              formHelperText="Min 200 characters"
            />
          </Grid>
        </Grid>
      </Box>
      <NextBackButtons
        onClickNext={() => onClickNext()}
        onClickBack={() => history.push(`/project/basics/${id}#step2`)}
        disabledNext={
          !(
            title &&
            shortDescription &&
            longDescription &&
            !Object.keys(errors).length
          )
        }
      />
    </div>
  );
}
