// Common
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useProjectStatuses } from '../../hooks/useProjectStatuses';

// Components
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import StatusLabel from './statusLabel';

// Actions
import { clearErrors } from '../../../actions/errors';
import { getOneProject, handleClearProject } from '../../../actions/projects';
import '../../../actions/sockets';

//Images
import BasicsIcon from '../../../images/basics_step_icon.svg';
import DescriptionIcon from '../../../images/description_step_icon.svg';
import VideoIcon from '../../../images/video_step_icon.svg';
import ImagesIcon from '../../../images/images_step_icon.svg';
import DocsIcon from '../../../images/docs_step_icon.svg';
import InvestmentsIcon from '../../../images/investments_step_icon.svg';
import SummaryIcon from '../../../images/summary_step_icon.svg';
import BusinessIcon from '../../../images/business_step_icon.svg';
import BasicsIconALt from '../../../images/basics_step_icon_alt.svg';
import DescriptionIconAlt from '../../../images/description_step_icon_alt.svg';
import VideoIconAlt from '../../../images/video_step_icon_alt.svg';
import ImagesIconAlt from '../../../images/images_step_icon_alt.svg';
import DocsIconAlt from '../../../images/docs_step_icon_alt.svg';
import InvestmentsIconAlt from '../../../images/investments_step_icon_alt.svg';
import SummaryIconAlt from '../../../images/summary_step_icon_alt.svg';
import BusinessIconAlt from '../../../images/business_step_icon_alt.svg';
import TopCorner from '../../../images/top_corner.svg';
import BottomCorner from '../../../images/bottom_corner.svg';

// Styles
import './index.scss';
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    padding: '0 !important',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    top: '82px',
    backgroundColor: '#62a4cd',
    paddingTop: '50px',
    border: 'none',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
    top: '82px',
    backgroundColor: '#62a4cd',
    paddingTop: '50px',
    border: 'none',
  },
  listItem: {
    marginLeft: '8px',
    padding: '16px 0 16px 6px',
    height: '56px',
    borderRadius: '28px 0 0 28px',
    color: '#fff',
    cursor: 'pointer',
  },
  activeListItem: {
    backgroundColor: '#fff',
    color: '#62a4cd',
    position: 'relative',
    '&::before': {
      content: `url(${BottomCorner})`,
      position: 'absolute',
      bottom: '-21px',
      right: '8px',
    },
    '&::after': {
      content: `url(${TopCorner})`,
      position: 'absolute',
      top: '-18px',
      right: '8px',
    },
  },
  listItemtext: {
    color: '#fff',
    fontFamily: 'SFProDisplay-SemiBold',
  },
  activeListItemText: {
    color: '#62a4cd',
  },
}));

const steps = [
  {
    name: 'Basics',
    icon: BasicsIcon,
    altIcon: BasicsIconALt,
    route: '/project/basics',
    statusLabel: 'basicStep1',
  },
  {
    name: 'Description',
    icon: DescriptionIcon,
    altIcon: DescriptionIconAlt,
    route: '/project/description',
    statusLabel: 'description',
  },
  {
    name: 'Video',
    icon: VideoIcon,
    altIcon: VideoIconAlt,
    route: '/project/video',
    statusLabel: 'video',
  },
  {
    name: 'Images',
    icon: ImagesIcon,
    altIcon: ImagesIconAlt,
    route: '/project/images',
    statusLabel: 'images',
  },
  {
    name: 'Docs',
    icon: DocsIcon,
    altIcon: DocsIconAlt,
    route: '/project/docs',
    statusLabel: 'docs',
  },
  {
    name: 'Investments',
    icon: InvestmentsIcon,
    altIcon: InvestmentsIconAlt,
    route: '/project/investments',
    statusLabel: 'investments',
  },
  {
    name: 'Business model',
    icon: BusinessIcon,
    altIcon: BusinessIconAlt,
    route: '/project/bisiness_model',
    statusLabel: 'bisiness_model',
  },
  {
    name: 'Summary',
    icon: SummaryIcon,
    altIcon: SummaryIconAlt,
    route: '/project/summary',
    statusLabel: 'summary',
  },
];

export function ProjectDrawer({ openDrawer }) {
  const [activeRoute, setActiveRoute] = useState('');
  const projectStatus = useSelector((state) => state.projects.selected.status);
  const statuses = useProjectStatuses();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (id !== 'new') dispatch(getOneProject(id));
    if (id === 'new') dispatch(handleClearProject());

    return () => dispatch(clearErrors());
  }, [id, dispatch]);

  useEffect(() => {
    steps.forEach((item) => {
      if (location.pathname.match(item.route)) {
        setActiveRoute(item.route);
      }
    });
  }, [location]);

  const changeStep = (route) => {
    if (
      projectStatus === 'In review' ||
      projectStatus === 'Published' ||
      projectStatus === 'Approved'
    ) {
      return;
    }
    let link = route + `/${id}`;
    if (route === '/project/basics') {
      link += '#step1';
    }
    if (id !== 'new') history.push(link);
  };

  const setDotColor = (item) => {
    if (item.route === activeRoute) {
      return '#fff';
    }
    if (
      statuses[item.statusLabel] === 'Done' ||
      statuses[item.statusLabel] === 'Approved' ||
      statuses[item.statusLabel] === 'Ready to send' ||
      statuses[item.statusLabel] === 'Published'
    ) {
      return '#41dc6c';
    }
    if (
      statuses[item.statusLabel] === 'Not Ready' ||
      statuses[item.statusLabel] === 'Waiting for Review' ||
      statuses[item.statusLabel] === 'In review'
    ) {
      return '#fdc22a';
    }

    if (statuses[item.statusLabel] === 'Rejected') {
      return '#f15642';
    }

    return '#62a4cd';
  };

  const checkStatus = () => {
    if (!activeRoute) {
      return 'Done';
    }
    let activeStep = steps.find((step) => step.route === activeRoute);
    if (activeStep?.name === 'Basics' && location.hash === '#step2') {
      activeStep.statusLabel = 'basicStep2';
    }
    if (activeStep?.name === 'Basics' && location.hash === '#step1') {
      activeStep.statusLabel = 'basicStep1';
    }
    return statuses[activeStep.statusLabel];
  };

  return (
    <div className="drawer_container">
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: !openDrawer,
          [classes.drawerClose]: openDrawer,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: !openDrawer,
            [classes.drawerClose]: openDrawer,
          }),
        }}
      >
        <List>
          {steps.map((item, index) => (
            <ListItem
              key={index}
              className={clsx(classes.listItem, {
                [classes.activeListItem]: item.route === activeRoute,
              })}
              onClick={() => changeStep(item.route)}
            >
              <span
                className="dot"
                style={{ backgroundColor: setDotColor(item) }}
              />
              <ListItemIcon>
                <img
                  src={item.route === activeRoute ? item.icon : item.altIcon}
                  alt="menu_icon"
                />
              </ListItemIcon>
              <ListItemText
                className={clsx(classes.listItemtext, {
                  [classes.activeListItemText]: item.route === activeRoute,
                })}
                primary={item.name}
                disableTypography={true}
              />
            </ListItem>
          ))}
        </List>
      </Drawer>
      {activeRoute && <StatusLabel status={checkStatus()} />}
    </div>
  );
}
