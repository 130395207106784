import { Button, Grid, Box } from '@material-ui/core';

export default function NextBackButtons({
  onClickBack,
  onClickNext,
  disabledNext,
}) {
  return (
    <div className="next_back_buttons_container">
      <Box minWidth="100%">
        <Grid
          container
          alignItems="center"
          alignContent="space-between"
          justify="space-between"
          wrap="nowrap"
        >
          <Grid item>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              onClick={() => onClickBack()}
            >
              Back
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="small"
              color="primary"
              disabled={disabledNext}
              onClick={() => onClickNext()}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
