// Common
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useValidation } from '../../hooks/useValidation';

// Components
import { TextField, InputAdornment, Typography } from '@material-ui/core';

// Icons
import DeleteIconGrey from '../../../images/icons_delete_circle.svg';
import DeleteIconActive from '../../../images/icons_delete_circle_active.svg';
import ErrorIcon from '../../../images/icons_error.svg';
import EyeIcon from '../../../images/icons_eye.svg';

// Styles
import './index.scss';

export function Input({ label, type, value, name, onChange, validation }) {
  const [clearImage, setClearImage] = useState(DeleteIconGrey);
  const [showError, setShowError] = useState(false);
  const [passwordType, setPasswordType] = useState('password');
  useValidation(value, name, validation);
  const error = useSelector((state) => state.errors[name]);

  const clearValue = () => {
    onChange({ target: { name: name, value: '' } });
  };

  const changeType = () => {
    passwordType === 'password'
      ? setPasswordType('text')
      : setPasswordType('password');
  };

  const renderError = () => {
    return error && showError ? (
      <div className="error_text">
        <img src={ErrorIcon} alt="error_icon" />
        <Typography color="error">{error}</Typography>
      </div>
    ) : (
      ''
    );
  };

  return (
    <>
      <TextField
        error={!!error && showError}
        fullWidth
        label={label}
        type={type === 'password' ? passwordType : type}
        value={value}
        name={name}
        onChange={onChange}
        variant="outlined"
        FormHelperTextProps={{ component: 'div' }}
        helperText={renderError()}
        InputProps={{
          endAdornment: value && (
            <InputAdornment position="end">
              {type === 'password' ? (
                <img
                  className="input_icon"
                  onClick={() => changeType()}
                  src={EyeIcon}
                  alt="delete_icon"
                />
              ) : (
                <img
                  className="input_icon"
                  onClick={() => clearValue()}
                  src={clearImage}
                  alt="delete_icon"
                />
              )}
            </InputAdornment>
          ),
          inputProps: {
            min: 0,
          },
        }}
        onFocus={() => setClearImage(DeleteIconActive)}
        onBlur={() => {
          setClearImage(DeleteIconGrey);
          setShowError(true);
        }}
        disabled={!onChange}
      />
    </>
  );
}
