import { SET_STATUSES } from '../actions/projects';

const initialState = {
  basicStep1: null,
  basicStep2: null,
  description: null,
  video: null,
  images: null,
  docs: null,
  investments: null,
  summary: null,
};

const projectStatusesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STATUSES:
      return { ...state, ...action.statuses };
    default:
      return state;
  }
};

export default projectStatusesReducer;
