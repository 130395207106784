import {
  getChats,
  createChat,
  sendMessage,
  getChat,
  deleteMessage,
} from '../services/messages';

export const SET_CHATS = 'MESSAGES/SET_CHATS';
export const SET_MESSAGES = 'MESSAGES/SET_MESSAGES';
export const ADD_NEW_MESSAGE = 'MESSAGES/ADD_NEW_MESSAGE';
export const SET_SELECTED_CHAT = 'MESSAGES/SET_SELECTED_CHAT';
export const CLEAR = 'MESSAGES/CLEAR';

export const removeMessage = (message_id, chat_id) => {
  return (dispatch) => {
    deleteMessage(message_id).then((res) => {
      getChat(chat_id).then((res) => {
        dispatch({
          type: SET_MESSAGES,
          messages: res.data.messages,
        });
      });
    });
  };
};

export const clearMessages = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR });
  };
};

export const setSelectedChat = (chat) => {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_CHAT,
      chat: chat,
    });
  };
};

export const setChats = () => {
  return (dispatch) => {
    getChats().then((res) => {
      dispatch({
        type: SET_CHATS,
        chats: res.data.rows,
      });
    });
  };
};

export const createMessage = (chat_id, message) => {
  return (dispatch) => {
    sendMessage(chat_id, message).then((res) => {
      dispatch({
        type: ADD_NEW_MESSAGE,
        message: res.data,
      });
    });
  };
};

export const newChat = (user_id, project_id, message) => {
  return (dispatch) => {
    createChat(user_id, project_id).then((res) => {
      console.log(res);
      sendMessage(res.data._id, message).then((res) => {
        console.log(res);
      });
    });
  };
};

export const getMessages = (chat_id) => {
  return (dispatch) => {
    getChat(chat_id).then((res) => {
      dispatch({
        type: SET_MESSAGES,
        messages: res.data.messages,
      });
    });
  };
};
