// Common
import { useDispatch, useSelector } from 'react-redux';

// Action
import { setError, clearError } from '../../actions/errors';

const linkRegex =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,1000}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

export const useValidation = (value, param, validation) => {
  const errors = useSelector((state) => state.errors);
  const dispatch = useDispatch();
  let error = '';

  if (!validation) return false;

  let validationsRules = {
    email: [
      {
        regexp: /\w/,
        message: 'Email has been required!',
      },
      {
        regexp:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        message: 'Please add a valid email address',
      },
      {
        regexp: /^.{3,120}$/,
        message: 'Max length 120, min length 3 symbols',
      },
    ],
    name: [
      {
        regexp: /\w/,
        message: 'Name has been required!',
      },
      {
        regexp: /^[ÆØÅæøåa-zA-Z_ ]{2,120}$/,
        message: 'Must contain only letters. Max 120 symbols, min 2 symbols',
      },
    ],
    password: [
      {
        regexp: /\w/,
        message: 'Pasword has been required!',
      },
      {
        regexp:
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,24}$/,
        message:
          'Must contain 8 symbols min in a combination of lower and uppercase letters, numbers and special character. Max 24 symbols.',
      },
    ],
    new_password: [
      {
        regexp: /\w/,
        message: 'Pasword has been required!',
      },
      {
        regexp:
          /^(?=.*[æøåa-z])(?=.*[æøåA-Z])(?=.*\d)(?=.*[@$!%*?&])[ÆØÅæøåA-Za-z\d@$!%*?&]{8,24}$/,
        message:
          'Must contain 8 symbols min in a combination of lower and uppercase letters, numbers and special character. Max 24 symbols.',
      },
    ],
    phone: [
      {
        regexp: /\w/,
        message: 'Mobile number has been required!',
      },
      {
        regexp: /^([0-9]{8,12})$/,
        message: "It's not mobile number",
      },
    ],
    mobile: [
      {
        regexp: /\w/,
        message: 'Mobile number has been required!',
      },
      {
        regexp: /^([0-9]{8,12})$/,
        message: "It's not mobile number",
      },
    ],
    company_name: [
      {
        regexp: /\w/,
        message: 'Company has been required!',
      },
      {
        regexp: /^[ÆØÅæøåa-zA-Z0-9 .-]{2,240}$/,
        message:
          'Must contain numbers and letters. Max 240 symbols, min 2 symbols.',
      },
    ],
    city: [
      {
        regexp: /\w/,
        message: 'City has been required!',
      },
      {
        regexp: /^[ÆØÅæøåa-zA-Z_ ]{2,240}$/,
        message: 'Must contain only letters. Max 240 symbols, min 2 symbols.',
      },
    ],
    address: [
      {
        regexp: /\w/,
        message: 'Address has been required!',
      },
      {
        regexp: /^[#.0-9ÆØÅæøåa-zA-Z\s,-]{2,500}$/,
        message: 'Max 500 symbols, min 2 symbols.',
      },
    ],
    password_old: [
      {
        regexp: /\w/,
        message: 'Old password has been required!',
      },
      {
        regexp:
          /^(?=.*[a-zæøå])(?=.*[A-ZÆØÅ])(?=.*\d)(?=.*[@$!%*?&])[ÆØÅæøåA-Za-z\d@$!%*?&]{8,24}$/,
        message:
          'Must contain 8 symbols min in a combination of lower and uppercase letters, numbers and special character. Max 24 symbols.',
      },
    ],
    password_new: [
      {
        regexp: /\w/,
        message: 'New password has been required!',
      },
      {
        regexp:
          /^(?=.*[a-zæøå])(?=.*[A-ZÆØÅ])(?=.*\d)(?=.*[@$!%*?&])[ÆØÅæøåA-Za-z\d@$!%*?&]{8,24}$/,
        message:
          'Must contain 8 symbols min in a combination of lower and uppercase letters, numbers and special character. Max 24 symbols.',
      },
    ],
    desc: [
      {
        regexp: /\w/,
        message: 'Details has been required!',
      },
      {
        regexp: /^[#.0-9ÆØÅæøåa-zA-Z\s,-]{2,500}$/,
        message: 'Max 500 symbols, min 2 symbols.',
      },
    ],
    facebook: [
      {
        regexp: /\w/,
        message: 'Details has been required!',
      },
      {
        regexp: linkRegex,
        message: "It's not a link!",
      },
    ],
    youtube: [
      {
        regexp: /\w/,
        message: 'Details has been required!',
      },
      {
        regexp: linkRegex,
        message: "It's not a link!",
      },
    ],
    linkedin: [
      {
        regexp: /\w/,
        message: 'Details has been required!',
      },
      {
        regexp: linkRegex,
        message: "It's not a link!",
      },
    ],
    twitter: [
      {
        regexp: /\w/,
        message: 'Details has been required!',
      },
      {
        regexp: linkRegex,
        message: "It's not a link!",
      },
    ],
    zip: [
      {
        regexp: /\w/,
        message: 'Zip has been required!',
      },
      {
        regexp: /^\D*(?:\d\D*){4,}$/,
        message: 'Is not a Zip Code!',
      },
    ],
    vatNumber: [
      {
        regexp: /\w/,
        message: 'Vat number has been required',
      },
    ],
    title: [
      {
        regexp: /\w/,
        message: 'Title has been required!',
      },
    ],
    longDescription: [
      {
        regexp: /^[\s\S]{200,}$/,
        message: 'Min 200 characters',
      },
    ],
    shortDescription: [
      {
        regexp: /^[\s\S]{200,}$/,
        message: 'Min 200 characters',
      },
    ],
    number: [
      {
        regexp: /\w/,
        message: 'This field has been required!',
      },
      {
        regexp: /^\d+$/,
        message: 'Must contain only numbers!',
      },
    ],
    sharesNumber: [
      {
        regexp: /\w/,
        message: 'This field has been required!',
      },
      {
        regexp: /^\d+$/,
        message: 'Must contain only numbers!',
      },
    ],
    price: [
      {
        regexp: /\w/,
        message: 'This field has been required!',
      },
      {
        regexp: /^\d+(\.\d+)?$/,
        message: 'Must contain only numbers!',
      },
    ],
    budget: [
      {
        regexp: /\w/,
        message: 'This field has been required!',
      },
      {
        regexp: /^\d+(\.\d+)?$/,
        message: 'Must contain only numbers!',
      },
    ],
    website: [
      {
        regexp: /\w/,
        message: 'This field has been required!',
      },
      {
        regexp:
          /^(http:\/\/|https:\/\/)[a-zA-Z0-9]+([-.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
        message: 'Please provide a valid URL. Example: https://example.com',
      },
    ],
    link: [
      {
        regexp: /\w/,
        message: 'This field has been required!',
      },
      {
        regexp:
          /^(http:\/\/|https:\/\/)[a-zA-Z0-9]+([-.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
        message: 'Please provide a valid URL. Example: https://example.com',
      },
    ],
  };

  for (const item of validationsRules[param]) {
    if (!item.regexp.test(value)) {
      error = item.message;
      break;
    }
  }

  if (
    !error &&
    validationsRules[param].find((item) => item.message === errors[param])
  ) {
    dispatch(clearError(param));
    return !!error;
  }

  if (error && errors[param] !== error) {
    dispatch(setError({ [param]: error }));
  }

  return !!error;
};
