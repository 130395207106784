// Common
import { Link } from 'react-router-dom';

// Componets
import {
  Box,
  createMuiTheme,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import { EditButton } from '../index';

// Images
import LeftArrow from '../../../images/icons_arrow_left.svg';

const TypographyHead = createMuiTheme({
  typography: {
    h1: {
      color: '#909090',
      fontSize: '18px',
      lineHeight: '28px',
      fontFamily: 'SFProDisplay-Medium',
    },
    h2: {
      color: '#393939',
      fontSize: '18px',
      lineHeight: '28px',
      fontFamily: 'SFProDisplay-Medium',
    },
  },
});

export default function Headerbuttons({ SubmitButton }) {
  return (
    <Box
      mt="100px"
      ml="40px"
      mr="40px"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Link to="/profile" style={{ textDecoration: 'none' }}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <img src={LeftArrow} alt="" />
          <ThemeProvider theme={TypographyHead}>
            <Typography variant="h1">Your Profile</Typography>
            &nbsp;
            <Typography variant="h2">/ Edite Profile</Typography>
          </ThemeProvider>
        </Box>
      </Link>

      <EditButton type="submit" onClick={SubmitButton}>
        Save
      </EditButton>
    </Box>
  );
}
