import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#62a4cd',
    },
    secondary: {
      main: '#b3bcc7',
    },
    error: {
      main: '#f15642',
    },
  },

  typography: {
    h1: {
      fontFamily: 'SFProDisplay-Medium',
      fontSize: 36,
      color: '#393939',
      fontWeight: 600,
      lineHeight: 1.39,
      letterSpacing: '0.36px',
    },
    h2: {
      fontWeight: 500,
      fontFamily: 'SFProDisplay-SemiBold',
      fontSize: 28,
      lineHeight: 1.43,
      letterSpacing: 0.28,
      color: '#393939',
    },
    h3: {
      fontFamily: 'SFProDisplay-SemiBold',
      fontWeight: 500,
      fontSize: 24,
      lineHeight: 1.56,
      color: '#393939',
    },
    h4: {
      fontFamily: 'SFProDisplay-SemiBold',
      fontWeight: 500,
      fontSize: 18,
      lineHeight: 1.56,
      color: '#393939',
    },
    h6: {
      fontFamily: 'SFProDisplay-Medium',
      fontSize: 26,
      fontWeight: 500,
      lineHeight: 1.54,
      color: '#565656',
    },
    subtitle1: {
      color: '#393939',
      lineHeight: 1.5,
      fontSize: '16px',
      fontWeight: 500,
      fontFamily: 'SFProDisplay-SemiBold',
    },
    subtitle2: {
      color: '#909090',
      fontSize: '16px',
      lineHeight: 1.75,
      letterSpacing: 0.5,
      fontFamily: 'SFProDisplay-Regular',
    },
    body1: {
      color: '#393939',
      lineHeight: 1.5,
      fontSize: '16px',
      fontFamily: 'SFProDisplay-Regular',
    },
    // body2 for aploader avatar
    body2: {
      color: '#393939',
      lineHeight: 1.5,
      fontSize: '50px',
      fontFamily: 'SFProDisplay-Bold',
    },
  },

  overrides: {
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: '#858585',
        },
        '&$error': {
          color: '#858585',
        },
        fontFamily: 'SFProDisplay-Regular',
        color: '#858585',
      },
      error: {},
      focused: {},
    },

    MuiInputBase: {
      root: {
        fontFamily: 'SFProDisplay-Regular',
        color: '#393939',
      },
    },

    MuiButton: {
      root: {
        width: 'auto',
        padding: '16px 40px',
        color: '#fff',
        fontWeight: 500,
        fontSize: '16px',
        '&$containedSizeLarge': {
          width: '366px',
          padding: '16px',
          color: '#fff',
          fontWeight: 500,
          fontSize: '16px',
        },
        '&$containedSizeSmall': {
          padding: '10px 40px',
          color: '#fff',
          fontWeight: 500,
          fontSize: '16px',
        },
        '&$containedPrimary:hover': {
          backgroundColor: '#79b5db',
        },
        '&$contained.Mui-disabled': {
          color: '#fff',
          backgroundColor: '#b3bcc7',
        },
        '&$outlinedSizeSmall': {
          padding: '10px 40px',
          fontWeight: 500,
          fontSize: '16px',
        },
        fontFamily: 'SFProDisplay-Regular',
        textTransform: 'none',
      },
      containedPrimary: {
        color: '#fff',
      },
    },

    MuiFormControlLabel: {
      root: {
        display: 'flex',
        alignItems: 'start',
        marginLeft: 0,
        marginRight: 0,
      },
    },

    MuiCheckbox: {
      root: {
        padding: 0,
        marginRight: '16px',
      },
    },

    MuiRadio: {
      root: {
        padding: 0,
        marginRight: '16px',
        marginBottom: '16px',
      },
    },

    MuiAppBar: {
      root: {
        height: '82px',
        backgroundColor: '#fff',
        boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.04)',
        display: 'flex',
      },
    },

    MuiTabs: {
      root: {
        height: '80px',
        textDecoration: 'none',
        fontFamily: 'SFProDisplay-SemiBold',
      },
    },

    MuiTab: {
      root: {
        height: '80px',
        textTransform: 'none',
        fontFamily: 'SFProDisplay-Bold',
        fontSize: '16px',
      },
    },

    MuiStep: {
      horizontal: {
        paddingRight: 0,
        paddingLeft: 0,
      },
    },

    MuiStepLabel: {
      labelContainer: {
        display: 'none',
      },
      iconContainer: {
        paddingRight: 0,
      },
    },

    MuiStepIcon: {
      root: {
        width: '28px',
        height: '28px',
        color: '#fff',
        border: 'solid 2px #62a4cd',
        borderRadius: '100%',
        backgroundColor: '#62a4cd',
      },
      text: {
        fill: '#62a4cd',
        fontFamily: 'SFProDisplay-SemiBold',
        fontSize: 13,
      },
      active: {
        border: 'none',
        color: '#fff !important',
        fill: '#62a4cd !important',
        width: '32px',
        height: '32px',
        '& $text': {
          fill: '#fff',
        },
      },
      completed: {
        width: '28px',
        height: '28px',
        color: '#fff !important',
      },
    },

    MuiStepConnector: {
      line: {
        border: 'solid 1px',
        borderColor: '#62a4cd',
      },
    },

    MuiTooltip: {
      tooltip: {
        backgroundColor: '#62a4cd',
        color: '#fff',
        fontSize: 12,
        fontFamily: 'SFProDisplay-Regular',
        padding: '10px 12px',
        borderColor: '#62a4cd',
        maxWidth: '100%',
        whiteSpace: 'pre',
      },
      arrow: {
        '&:before': {
          border: '1px solid #62a4cd',
          bottom: '10px',
        },
        color: '#62a4cd',
        marginBottom: '-.58em !important',
      },
    },

    MuiDialog: {
      paper: {
        padding: '48px 84px',
        borderRadius: '24px',
        overflowY: 'none',
      },
    },

    MuiDialogContent: {
      root: {
        paddingLeft: '50px',
        paddingRight: '50px',
        paddingBottom: '40px',
        paddingTop: '40px',
      },
    },

    MuiDialogActions: {
      root: {
        justifyContent: 'center',
      },
    },
  },
});

export default theme;
