import { useState } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { removeMessage } from '../../actions/messages';
import { getAbbreviation } from '../profile/abbreviation';
import { makeStyles } from '@material-ui/core/styles';
import { Menu, MenuItem, ListItemIcon } from '@material-ui/core';
// import MoreIcon from '../../images/icons_more_greay.svg';
import DeleteIcon from '../../images/icons_Delete.svg';
import './index.scss';

const useStyles = makeStyles({
  button: {
    border: 'none',
    fontSize: '16px',
    lineHeight: 1.5,
    color: '#393939',
    fontFamily: 'SFProDisplay-Medium',
    '&:hover': {
      backgroundColor: 'rgb(0,0,0,0)',
    },
    position: 'absolute',
    right: -45,
    top: 'calc(50% - 24px)',
  },
  buttonOwner: {
    border: 'none',
    fontSize: '16px',
    lineHeight: 1.5,
    color: '#393939',
    fontFamily: 'SFProDisplay-Medium',
    '&:hover': {
      backgroundColor: 'rgb(0,0,0,0)',
    },
    position: 'absolute',
    left: -45,
    bottom: 'calc(50% - 24px)',
  },
  rootMenu: {
    '& .MuiPaper-root': {
      width: '201px',
      boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.07)',
      borderRadius: '8px',
      padding: '8px 0  ',
    },
  },
  menuItem: {
    height: '48px',
    fontSize: '14px',
    fontFamily: 'SFProDisplay-Medium',
    color: '#393939',
    lineHeight: 1.29,
    '& .MuiListItemIcon-root': {
      minWidth: '36px',
    },
  },
});

export default function Message({ message, profile_id }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const classes = useStyles();

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteMessage = () => {
    dispatch(removeMessage(message._id, message.chat));
    handleClose();
  };

  return (
    message.status !== 'Deleted' && (
      <div>
        {profile_id === message.sender._id ? (
          <div className="message_container owner_message">
            <div className="message_content owner_content">
              <div className="text_content owner_text">
                {message.message.content}
                {/* <IconButton
                  className={classes.buttonOwner}
                  onClick={handleClick}
                >
                  <img className="more_image" src={MoreIcon} alt="more" />
                </IconButton> */}
              </div>
              <div className="message_time">
                {moment(message.createdAt).format('HH:mm')}
              </div>
            </div>
          </div>
        ) : (
          <div className="message_container">
            {message.sender.picture ? (
              <img
                className="message_avatar"
                src={message.sender.picture}
                alt="avatar"
              />
            ) : (
              <div className="message_avatar">
                {getAbbreviation(message.sender.name)}
              </div>
            )}
            <div className="message_content">
              <div className="text_content">
                {message.message.content}
                {/* <IconButton className={classes.button} onClick={handleClick}>
                  <img className="more_image" src={MoreIcon} alt="more" />
                </IconButton> */}
              </div>
              <div className="message_time">
                {moment(message.createdAt).format('HH:mm')}
              </div>
            </div>
          </div>
        )}
        <Menu
          className={classes.rootMenu}
          elevation={0}
          getContentAnchorEl={null}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {profile_id === message.sender._id && (
            <MenuItem
              className={classes.menuItem}
              onClick={() => handleDeleteMessage()}
            >
              <ListItemIcon>
                <img src={DeleteIcon} alt="delete_icon" />
              </ListItemIcon>
              Delete message
            </MenuItem>
          )}
        </Menu>
      </div>
    )
  );
}
