// Common
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';

// Action
import { uploadVideo, removeVideo } from '../../actions/projects';

//Components
import { Typography, Box } from '@material-ui/core';
import VideoUploader from '../common/videoUploader/index.js';
import NextBackButtons from './nextBackButtons';

export default function Video() {
  const videoUrl = useSelector((state) => state.projects.selected.video);
  const videoUploading = useSelector((state) => state.projects.uploadingFile);
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const selectVideo = (event) => {
    dispatch(uploadVideo(event.target.files[0], id));
  };

  const onClickNext = () => {
    history.push(`/project/images/${id}`);
  };

  return (
    <div className="project_form_container">
      <Typography variant="h2">60 seconds pitch</Typography>
      <Box mt="10px">
        <Typography variant="subtitle2">
          Create a 60-second video where you tell about your project and why
          someone should invest in your project. If you are having trouble with
          video uploads, consider compressing the video to reduce its size and
          upload the file here again. You may use the{' '}
          <a href="https://freeconvert.com" target="_blank" rel="noreferrer">
            Freeconvert.com
          </a>{' '}
          service or similar.
        </Typography>
      </Box>
      <Box mt="48px" maxWidth="450px">
        <Typography variant="h3">Upload Video</Typography>
      </Box>
      <Box mt="24px" mb="48px">
        <VideoUploader
          name="video"
          onChange={selectVideo}
          videoUrl={videoUrl}
          onDestroy={() =>
            dispatch(
              removeVideo(id, [
                videoUrl.replace(
                  'https://moneyflow-back.s3.us-east-2.amazonaws.com/',
                  '',
                ),
              ]),
            )
          }
          videoUploading={videoUploading}
        />
      </Box>
      <NextBackButtons
        onClickNext={() => onClickNext()}
        onClickBack={() => history.push(`/project/description/${id}`)}
        disabledNext={false}
      />
    </div>
  );
}
