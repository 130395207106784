// Common
import { Box } from '@material-ui/core';
import { useMemo, useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Actions
import { editProfButton, updateAvatar } from '../../../actions/user';

// Components
import Headerbuttons from './headerButtons';
import InputsBox from './inputsBox';
import AploadPhotos from './upoadPhoto';

export default function EditProfile() {
  const user = useSelector((state) => state.user.data);
  const dispatch = useDispatch();
  const [inputFile, setInputFile] = useState(null);
  const [userInfo, setUserInfo] = useState({
    name: user?.name,
    company_name: user?.company?.name,
    email: user?.email,
    phone: user?.phone,
    city: user?.city,
    address: user?.address,
    password_old: '',
    password_new: '',
    desc: user?.company?.details,
    facebook: user?.links?.facebook,
    youtube: user?.links?.youtube,
    linkedin: user?.links?.linkedin,
    twitter: user?.links?.twitter,
  });

  useEffect(() => {
    if (inputFile !== null) {
      dispatch(updateAvatar(inputFile));
    }
  }, [dispatch, inputFile]);

  const info = useMemo(() => {
    return [
      {
        title: 'Name',
        label: 'Full name',
        value: userInfo.name,
        name: 'name',
        type: 'text',
        validation: true,
      },
      {
        title: 'Company name',
        label: 'Company name',
        value: userInfo.company_name,
        name: 'company_name',
        type: 'text',
        validation: true,
      },
      {
        title: 'Contacts',
        label: 'Email',
        value: userInfo.email,
        name: 'email',
        type: 'text',
        validation: true,
      },
      {
        label: 'Mobile',
        value: userInfo.phone,
        name: 'phone',
        type: 'tel',
        validation: true,
      },
      {
        label: 'City',
        value: userInfo.city,
        name: 'city',
        type: 'text',
        validation: true,
      },
      {
        label: 'Street address',
        value: userInfo.address,
        name: 'address',
        type: 'text',
        validation: true,
      },
      {
        title: 'Password',
        label: 'Old password',
        value: userInfo.password_old,
        name: 'password_old',
        type: 'password',
        validation: true,
      },
      {
        label: 'Password',
        value: userInfo.password_new,
        name: 'password_new',
        type: 'password',
        validation: true,
      },
      {
        title: 'Description',
        label: 'Description',
        value: userInfo.desc,
        name: 'desc',
        type: 'text',
        validation: true,
      },
      {
        title: 'Social media',
        subtitle: 'Facebook',
        label: 'Your link',
        value: userInfo.facebook,
        name: 'facebook',
        type: 'text',
        validation: true,
      },
      {
        label: 'Your link',
        subtitle: 'Youtube',
        value: userInfo.youtube,
        name: 'youtube',
        type: 'text',
        validation: true,
      },
      {
        label: 'Your link',
        subtitle: 'LinkedIn',
        value: userInfo.linkedin,
        name: 'linkedin',
        type: 'text',
        validation: true,
      },
      {
        label: 'Your link',
        subtitle: 'Twitter',
        value: userInfo.twitter,
        name: 'twitter',
        type: 'text',
        validation: true,
      },
    ];
  }, [userInfo]);

  const handleChange = (event) => {
    setUserInfo({
      ...userInfo,
      [event.target.name]: event.target.value,
    });
  };

  const SubmitButton = useCallback(() => {
    dispatch(editProfButton(userInfo, user._id, user?.picture));
  }, [dispatch, userInfo, user._id, user?.picture]);

  return (
    <Box>
      <Headerbuttons SubmitButton={SubmitButton} />
      <AploadPhotos
        id={user._id}
        picture={user?.picture}
        setInputFile={(file) => setInputFile(file)}
        name={user.name}
      />
      <InputsBox info={info} handleChange={handleChange} />
    </Box>
  );
}
