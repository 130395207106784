// Common
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Link as routerLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import history from '../../../history';

// Components
import SessionContainer from '../session_container';
import { Grid, Typography, Button, Link, Box } from '@material-ui/core';
import { Input } from '../../common/input/index';

// Actions
import { resetPassword } from '../../../services/user';
import { setError, clearError } from '../../../actions/errors';

export default function PasswordReset(props) {
  const [user, setUser] = useState({
    new_password: '',
    password_confirm: '',
    token: props.match.params.id,
  });
  const errors = useSelector((state) => state.errors);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = useCallback(() => {
    resetPassword(user)
      .then((res) => {
        if (res.status === 200) {
          history.push('/login');
        }
      })
      .catch(() => {
        dispatch(
          setError({
            new_password: 'This password reset token is invalid.',
            password_confirm: 'This password reset token is invalid.',
          }),
        );
      });
  }, [dispatch, user]);

  useEffect(() => {
    if (user.new_password !== user.password_confirm) {
      dispatch(setError({ password_confirm: 'Your password does not match' }));
      return;
    }
    dispatch(clearError('password_confirm'));
  }, [dispatch, user]);

  const data = useMemo(() => {
    return [
      {
        label: 'New password',
        value: user.new_password,
        name: 'new_password',
        type: 'password',
        validation: true,
      },
      {
        label: 'Confirm password',
        value: user.password_confirm,
        name: 'password_confirm',
        type: 'password',
        validation: false,
      },
    ];
  }, [user]);

  return (
    <SessionContainer>
      <Grid item>
        <Typography variant="h1">Reset password</Typography>
        <Typography variant="subtitle2">
          Your new password must be different from previous used passwords
        </Typography>
      </Grid>
      {data.map((item) => (
        <Grid key={item.label} item>
          <Input
            label={item.label}
            name={item.name}
            type={item.type}
            value={item.value}
            onChange={handleChange}
            validation={item.validation}
          />
        </Grid>
      ))}
      <Grid item>
        <Button
          size="large"
          color="primary"
          variant="contained"
          disabled={
            (!user.new_password && !user.password_confirm) ||
            !!Object.keys(errors).length
          }
          onClick={handleSubmit}
        >
          Reset password
        </Button>
        <Box mt="16px">
          <Typography m={4}>
            Already have an account?{' '}
            <Link underline="none" component={routerLink} to="/login">
              Log in
            </Link>
          </Typography>
        </Box>
      </Grid>
    </SessionContainer>
  );
}
