import React from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import CheckIcons from '../../../images/icons_check_white.svg';

import './index.scss';

export function CheckBox({ label, value, name, onChange }) {
  const icon = () => {
    return <span className="checkbox_icon" />;
  };

  const checkedIcon = () => {
    return (
      <span className="checkbox_icon_active">
        <img src={CheckIcons} alt="check_icon" />
      </span>
    );
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          name={name}
          color="primary"
          icon={icon()}
          checkedIcon={checkedIcon()}
          checked={value}
          onChange={onChange}
        />
      }
      label={label}
    />
  );
}
