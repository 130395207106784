// Common
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

// Components
import { Box, Grid, Typography, Button } from '@material-ui/core';
import EmptyDashboard from './empty_dashboard';
import ProjectItem from './projecItem';

// Actions
import { projectsList, updateProject } from '../../actions/projects';

// Styles
import './index.scss';

export default function Dashboard() {
  const projects = useSelector((state) => state.projects.index);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(projectsList());
  }, [dispatch]);

  const goToProject = (id, status) => {
    if (
      status === 'In review' ||
      status === 'Published' ||
      status === 'Approved'
    ) {
      history.push(`/project/summary/${id}`);
    } else {
      history.push(`/project/basics/${id}#step1`);
    }
  };

  const onClickEditBtn = (event, project) => {
    event.stopPropagation();
    event.preventDefault();
    project.status = null;

    dispatch(updateProject(project));
    history.push(`/project/basics/${project._id}#step1`);
  };

  return (
    <>
      {!projects ? (
        'loading...'
      ) : projects.length ? (
        <div className="dashboard_scroll_container">
          <div className="dashboard_container">
            <Box mt="58px" mb="30px">
              <Grid container alignItems="center" justify="space-between">
                <Grid item>
                  <Typography variant="h1">Dashboard</Typography>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => history.push('/project/basics/new#step1')}
                  >
                    Create
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <div className="firs_line" />
            <Grid
              container
              alignItems="center"
              justify="flex-start"
              wrap="wrap"
            >
              {projects.map((item) => (
                <Grid
                  key={item._id}
                  item
                  onClick={() => goToProject(item._id, item.status)}
                >
                  <ProjectItem
                    logo={item?.logo}
                    title={item?.title}
                    shortDescription={item?.shortDescription}
                    status={item?.status}
                    item={item}
                    onClickEditBtn={onClickEditBtn}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      ) : (
        <EmptyDashboard />
      )}
    </>
  );
}
