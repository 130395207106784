// Common
import React, { useState, useCallback } from 'react';
import { Link as routerLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import history from '../../../history';

// Actions
import { setError, clearErrors } from '../../../actions/errors';
import { forgotPassword } from '../../../services/user';

// Components
import SessionContainer from '../session_container';
import { Grid, Typography, Button, Link, Box } from '@material-ui/core';
import { Input } from '../../common/input/index';

// Images
import Svg from '../../../images/icons_Send.svg';

// Styles
import './index.scss';

export default function PasswordForgot() {
  const [email, setEmail] = useState('');
  const error = useSelector((state) => state.errors.email);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    dispatch(clearErrors());
    setEmail(event.target.value);
  };

  const handleSubmit = useCallback(() => {
    forgotPassword(email)
      .then((res) => {
        if (res.status === 200) {
          dispatch(clearErrors());
          // set reset passwold values
          localStorage.setItem('moneyflow-reset-password-email', email);
          localStorage.setItem(
            'moneyflow-reset-password-token',
            res.data.token,
          );
          history.push('/password_forgot/verification');
        }
      })
      .catch(() => {
        dispatch(setError({ email: `Account with this email not found.` }));
      });
  }, [dispatch, email]);

  return (
    <SessionContainer>
      <Grid item>
        <Typography variant="h1">Forgot password</Typography>
        <Typography variant="subtitle2">
          Enter the email associated with your account and we’ll send an email
          with instructions to reset your password
        </Typography>
      </Grid>
      <Grid key="Email" item>
        <Box mt="22px" mb="36px">
          <Input
            label="Email"
            name="email"
            type="text"
            value={email}
            onChange={handleChange}
            validation={true}
          />
        </Box>
      </Grid>
      <Grid item>
        <Button
          onClick={handleSubmit}
          size="large"
          color="primary"
          variant="contained"
          disabled={!error && !email}
        >
          Send
          <img src={Svg} className="icons-Send" alt="send" />
        </Button>
        <Box mt="16px">
          <Typography m={4}>
            Already have an account?{' '}
            <Link underline="none" component={routerLink} to="/login">
              Log in
            </Link>
          </Typography>
        </Box>
      </Grid>
    </SessionContainer>
  );
}
