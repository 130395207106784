import axios from 'axios';

const axiosInstans = axios.create();
const API_URL = process.env.REACT_APP_API_URL;

export const getProjects = () => {
  axios.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${localStorage.getItem('session_token')}`;
  return axios.get(`${API_URL}/projects/all`);
};

export const postProject = (project) => {
  axios.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${localStorage.getItem('session_token')}`;
  return axios.post(`${API_URL}/projects/`, project);
};

export const putProject = (project) => {
  axios.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${localStorage.getItem('session_token')}`;
  return axios.put(`${API_URL}/projects/${project._id}`, project);
};

export const getProject = (id) => {
  axios.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${localStorage.getItem('session_token')}`;
  return axios.get(`${API_URL}/projects/details/${id}`);
};

export const postVideo = (video, progectId) => {
  axios.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${localStorage.getItem('session_token')}`;
  let videoData = new FormData();
  videoData.append('video', video);
  videoData.append('title', video.name);
  return axios.post(`${API_URL}/files/video/${progectId}`, videoData);
};

export const deleteVideo = (projectId, path) => {
  axios.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${localStorage.getItem('session_token')}`;
  return axios.delete(`${API_URL}/files/${projectId}/video`, {
    data: { path },
  });
};

export const postMainImage = (image, progectId) => {
  axios.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${localStorage.getItem('session_token')}`;
  let data = new FormData();
  data.append('image', image);
  return axios.post(`${API_URL}/files/image/${progectId}`, data);
};

export const postLogo = (image, progectId) => {
  axios.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${localStorage.getItem('session_token')}`;
  let data = new FormData();
  data.append('image', image);
  return axios.post(`${API_URL}/files/image/logo/${progectId}`, data);
};

export const postTeamMemberAvatar = (image) => {
  axios.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${localStorage.getItem('session_token')}`;
  let data = new FormData();
  data.append('image', image);
  return axios.post(`${API_URL}/files/team-member`, data);
};

export const postAdditionalImage = (image, progectId) => {
  axios.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${localStorage.getItem('session_token')}`;
  let data = new FormData();
  data.append('image', image);
  return axios.post(`${API_URL}/files/images/${progectId}`, data);
};

export const deleteImage = (projectId, path, imageType) => {
  axios.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${localStorage.getItem('session_token')}`;
  return axios.delete(`${API_URL}/files/${projectId}/${imageType}`, {
    data: { path },
  });
};

export const postDocuments = (doc, progectId) => {
  axios.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${localStorage.getItem('session_token')}`;
  let data = new FormData();
  data.append('docs', doc[0], doc[0].name);
  return axios.post(`${API_URL}/files/documents/${progectId}`, data);
};

export const reviewHistory = (projectId) => {
  axios.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${localStorage.getItem('session_token')}`;
  return axios.get(`${API_URL}/reviews/reviewhistory/${projectId}`);
};

export const getInvestors = (projectId) => {
  axios.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${localStorage.getItem('session_token')}`;
  return axios.get(`${API_URL}/users/investors/${projectId}`);
};

export const getPreview = (previewId) => {
  return axiosInstans.get(`${API_URL}/projects/preview/${previewId}`);
};
