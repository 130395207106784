// Common
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getMessages,
  createMessage,
  clearMessages,
} from '../../actions/messages';
import ChatHeader from './chatHeader';
import Message from './message';
import { TextField, IconButton, Popover } from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Picker } from 'emoji-mart';

import IconSmile from '../../images/icons_smiles.svg';
import IconPaperclip from '../../images/icons_paperclip.svg';
import IconSend from '../../images/buttons_send.svg';
import 'emoji-mart/css/emoji-mart.css';
import './index.scss';

const theme = createMuiTheme({
  overrides: {
    PrivateNotchedOutline: {
      root: {
        border: 'none !important',
        borderRarius: '100% !important',
      },
    },

    MuiOutlinedInput: {
      root: {
        fontFamily: 'SFProDisplay-Regular',
        borderRadius: '28px !important',
        color: '#393939',
        backgroundColor: '#f7f7f7',
        '&$multiline': {
          paddingLeft: '24px',
          paddingRight: '0px',
          paddingTop: '0px',
          paddingBottom: '0px',
        },
      },
    },
  },
});

export default function Chat({ chat_id }) {
  const [newMessage, setNewMessage] = useState('');
  const [contact, setContact] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const profile_id = useSelector((state) => state.user.data._id);
  const messages = useSelector((state) => state.messages.messages);
  const selectedChat = useSelector((state) => state.messages.selectedChat);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMessages(chat_id));
    return () => dispatch(clearMessages());
  }, [dispatch, chat_id]);

  useEffect(() => {
    if (selectedChat?.users) {
      let _contact = selectedChat.users.find((item) => item._id !== profile_id);
      setContact(_contact);
    }
  }, [selectedChat, profile_id]);

  const handleChangeNewMessage = (event) => {
    setNewMessage(event.target.value);
  };

  const sendMessage = () => {
    if (newMessage) {
      dispatch(
        createMessage(chat_id, { content: newMessage, contentType: 'text' }),
      );
      setNewMessage('');
    }
  };

  const startIcon = () => {
    return (
      <IconButton>
        <img className="paperclip_icon" src={IconPaperclip} alt="paperclip" />
      </IconButton>
    );
  };

  const endIcons = () => {
    return (
      <div className="end_buttons">
        <IconButton onClick={handleClick}>
          <img className="smile_icon" src={IconSmile} alt="smile" />
        </IconButton>
        <IconButton onClick={() => sendMessage()}>
          <img className="send_icon" src={IconSend} alt="send" />
        </IconButton>
      </div>
    );
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEmojiSelect = (emoji) => {
    setNewMessage(newMessage + emoji?.native + ' ');
  };

  return (
    <div className="chat">
      <ChatHeader contact={contact} />
      <div className="messages_container">
        {messages.map((message) => (
          <Message message={message} profile_id={profile_id} />
        ))}
      </div>
      <div className="message_input_wrapper">
        <ThemeProvider theme={theme}>
          <TextField
            placeholder="Put some text here"
            variant="outlined"
            // label={false}
            fullWidth
            multiline
            maxRows={4}
            value={newMessage}
            onChange={handleChangeNewMessage}
            InputProps={{
              startAdornment: startIcon(),
              endAdornment: endIcons(),
            }}
          />
        </ThemeProvider>
        <Popover
          elevation={0}
          getContentAnchorEl={null}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Picker
            showPreview={false}
            showSkinTones={false}
            onSelect={handleEmojiSelect}
            color="#62a4cd"
          />
        </Popover>
      </div>
    </div>
  );
}
