import { Box, Grid, Typography } from '@material-ui/core';
import { Input } from '../../common/input/index';

export default function InputsBox({ info, handleChange }) {
  return (
    <Box
      pt="50px"
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      paddingBottom="76px"
    >
      <Grid container xs={8} justify="center">
        {info.map((element, index) => (
          <Grid item xs={8} key={index}>
            <Typography
              style={{ marginBottom: '24px ', marginTop: '16px' }}
              variant="h5"
            >
              {element.title}
            </Typography>
            <Typography style={{ marginBottom: '16px ' }} variant="subtitle1">
              {element.subtitle}
            </Typography>
            <Input
              value={element.value}
              label={element.label}
              name={element.name}
              type={element.type}
              onChange={handleChange}
              validation={element.validation}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
