// Common
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';

// Components
import { Typography, Box, Grid } from '@material-ui/core';
import DocUploader from '../common/docsUploader/index';
import { Input } from '../common/input';
import NextBackButtons from './nextBackButtons';

// Actions
import {
  changePrototypeLink,
  uploadDocuments,
  updateProject,
  handleChangeProject,
  removeImage,
} from '../../actions/projects';

// Styles
import './index.scss';
import { CustomSwitch } from '../common/switch';

export default function Docs() {
  const prototypeLinks = useSelector(
    (state) => state.projects.selected.prototypeLinks,
  );
  const documents = useSelector((state) => state.projects.selected.documents);
  const docUploading = useSelector((state) => state.projects.uploadingFile);
  const project = useSelector((state) => state.projects.selected);
  const errors = useSelector((state) => state.errors);
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const handleChangeLink = (event) => {
    let prototypeLink = { [event.target.name]: event.target.value };
    dispatch(changePrototypeLink({ ...prototypeLinks, ...prototypeLink }));
  };

  const selectAdditionalDoc = (event) => {
    dispatch(uploadDocuments(event.target.files, id));
    dispatch(updateProject(project));
  };

  const onClickNext = () => {
    dispatch(updateProject(project, `/project/investments/${project._id}`));
  };

  const handleChangeNDA = (event) => {
    dispatch(
      handleChangeProject({ [event.target.name]: event.target.checked }),
    );
  };

  return (
    <div className="project_form_container">
      <Typography variant="h2">Assets</Typography>
      <Box mt="10px">
        <Typography variant="subtitle2">
          On this page you can upload assets like images, Business model,
          documents and other assets to spice up your investor deck
        </Typography>
      </Box>
      <Box mt="48px">
        <Box mb="48px">
          <Grid spacing={6} container>
            <Grid item>
              <Typography variant="h4">Link title</Typography>
              <br />
              <br />
              <Input
                label={'Link title'}
                name="title"
                type={'text'}
                value={prototypeLinks.title}
                onChange={handleChangeLink}
                validation={false}
              />
            </Grid>
            <Grid item>
              <Typography variant="h4">
                URL to External presentation or prototype
              </Typography>
              <br />
              <br />
              <Input
                label={'Your link'}
                name="link"
                type={'text'}
                value={prototypeLinks.link}
                onChange={handleChangeLink}
                validation={true}
              />
            </Grid>
          </Grid>
        </Box>
        <div className="underline" />
      </Box>
      <Box mt="30px" mb="30px">
        <Typography variant="h4">Additional documents</Typography>
      </Box>
      <Box mb="48px">
        <Grid container spacing={3} alignItems="center">
          {!!documents.length &&
            documents.map((doc, index) => (
              <Grid item key={index}>
                <DocUploader
                  name="document"
                  onChange={selectAdditionalDoc}
                  docUrl={doc}
                  docUploading={docUploading}
                  onDestroy={() => {
                    dispatch(
                      removeImage(
                        id,
                        [
                          doc.url.replace(
                            'https://moneyflow-back.s3.us-east-2.amazonaws.com/',
                            '',
                          ),
                        ],
                        'documents',
                      ),
                    );
                  }}
                />
              </Grid>
            ))}
          <Grid item>
            {documents.length <= 15 && (
              <DocUploader
                name="document"
                onChange={selectAdditionalDoc}
                docUrl={null}
                docUploading={docUploading}
              />
            )}
          </Grid>
        </Grid>
      </Box>

      <Box mb="48px">
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <Box mt="30px" mb="30px">
              <Typography variant="h4">Project under NDA</Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box mt="30px" mb="30px">
              <CustomSwitch
                checked={project.isUnderNDA}
                onChange={handleChangeNDA}
                name="isUnderNDA"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <NextBackButtons
        onClickNext={() => onClickNext()}
        onClickBack={() => history.push(`/project/images/${id}`)}
        disabledNext={errors.length && !documents.length}
      />
    </div>
  );
}
