// Common
import { Link } from 'react-router-dom';

// Components
import {
  Box,
  Button,
  ThemeProvider,
  Typography,
  withStyles,
} from '@material-ui/core';
import { LinkStyle } from '../session/password_forgot/password_forgot_modal';

// Images
import AvatarCard from './avatarCard';
import ContactsCard from './contactsCard';
import DetailsCard from './detailsCard';

// Styles
import { title } from './customStyles';

export const EditButton = withStyles({
  root: {
    width: '132px',
    height: '44px',
    background: '#62a4cd',
    color: 'white',
    fontFamily: 'SFProDisplay-Medium',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '1.5',
    '&:hover': {
      background: '#62a4cd',
    },
  },
})(Button);

export default function Profile() {
  return (
    <>
      <Box
        mt="140px"
        ml="40px"
        mr="40px"
        display="flex"
        justifyContent="space-between"
      >
        <ThemeProvider theme={title}>
          <Typography variant="h1">Your Profile</Typography>
        </ThemeProvider>
        <Link style={LinkStyle} to="/profile/edit">
          <EditButton>Edit</EditButton>
        </Link>
      </Box>
      <Box display="flex" ml="40px" mt="42px">
        <AvatarCard />
        <DetailsCard />
        <ContactsCard />
      </Box>
    </>
  );
}
