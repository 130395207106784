import { Box, ThemeProvider, Typography } from '@material-ui/core';
import { details } from './customStyles';
import { useSelector } from 'react-redux';

export default function DetailsCard() {
  const user = useSelector((state) => state.user.data);

  return (
    <Box
      width="503px"
      height="334px"
      boxShadow="0 0 15px 0 rgba(0, 0, 0, 0.07)"
      marginLeft="32px"
    >
      <Box m="32px 32px 38px 32px">
        <ThemeProvider theme={details}>
          <Typography variant="h1">Details</Typography>
          <Typography variant="h2">Company name</Typography>
          <Typography variant="h3" color="secondary">
            {user.company?.name}
          </Typography>
          <Typography variant="h2">Description</Typography>
          <Typography variant="h3" color="secondary">
            {user.company?.details}
          </Typography>
        </ThemeProvider>
      </Box>
    </Box>
  );
}
