// Common
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setChats, setSelectedChat } from '../../actions/messages';
import ChatItem from './chatItem';

import './index.scss';

export default function ChatList({ chat_id }) {
  const chats = useSelector((state) => state.messages.chats);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setChats());
  }, [dispatch]);

  useEffect(() => {
    if (chat_id) {
      const selectedChat = chats.find((item) => item._id === chat_id);
      dispatch(setSelectedChat(selectedChat));
    }
  }, [chat_id, chats, dispatch]);

  return (
    <div className="chat_list">
      <h1 className="messages_title">Messages</h1>
      <div className="scroll_wrapper">
        {chats.map((item) => (
          <ChatItem chat={item} active={chat_id === item._id} />
        ))}
      </div>
    </div>
  );
}
