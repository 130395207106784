// Components
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Box,
} from '@material-ui/core';

// Images
import CheckIcon from '../../../images/icons_check_white.svg';

// Styles
import './index.scss';

export function RadioButtons({ values, name, value, onChange }) {
  return (
    <Box my="24px">
      <FormControl component="fieldset">
        <RadioGroup name={name} value={value} onChange={onChange}>
          {values.map((item, index) => (
            <FormControlLabel
              key={index}
              value={item.value}
              control={<StyledRadio />}
              label={item.label}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
}

function StyledRadio(props) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={
        <span className="active_radio">
          <img src={CheckIcon} alt="check_icon" />
        </span>
      }
      icon={<span className="inactive_radio" />}
      {...props}
    />
  );
}
