import { SET_USER, SET_CHECK_USER } from '../actions/user';

const initialState = {
  data: {
    email: '',
    phone: '',
    name: '',
    company_name: '',
    city: '',
    address: '',
    password_old: '',
    password_new: '',
    details: '',
    facebook: '',
    youtube: '',
    linkedin: '',
    twitter: '',
    role: 'owner',
    isReceivingNewsletter: false,
    isConfirmed: false,
    picture: null,
    createdAt: null,
    _id: null,
  },
  userChecked: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        data: action.user,
      };

    case SET_CHECK_USER:
      return {
        ...state,
        userChecked: action.userChecked,
      };
    default:
      return state;
  }
};

export default userReducer;
