import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const getChats = () => {
  axios.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${localStorage.getItem('session_token')}`;
  return axios.get(`${API_URL}/chats`);
};

export const createChat = (user_id, project_id) => {
  axios.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${localStorage.getItem('session_token')}`;
  return axios.post(`${API_URL}/chats`, {
    otherUserId: user_id,
    projectId: project_id,
  });
};

export const sendMessage = (conversationId, message) => {
  axios.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${localStorage.getItem('session_token')}`;
  return axios.post(`${API_URL}/messages`, {
    conversationId: conversationId,
    message: message,
  });
};

export const getChat = (chat_id) => {
  axios.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${localStorage.getItem('session_token')}`;
  return axios.get(`${API_URL}/chats/${chat_id}`);
};

export const deleteMessage = (message_id) => {
  axios.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${localStorage.getItem('session_token')}`;
  return axios.delete(`${API_URL}/messages/${message_id}`);
};
