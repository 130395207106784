// Images
import { useEffect, useState } from 'react';
import { showFile } from '../../../services/user';
import MessageIcon from '../../../images/icons_message_grey.svg';
import { getAbbreviation } from '../../profile/abbreviation';
import '../index.scss';

export default function InvestorsItem({ item, openMessgaeDialog }) {
  const [avatar, setAvatar] = useState(null);
  useEffect(() => {
    if (item.picture) {
      showFile(item.picture).then((res) => setAvatar(res.data));
    }
    return () => setAvatar('');
  }, [item]);

  return (
    <div className="investors_item">
      <div className="investor_info">
        {item.picture ? (
          <img src={avatar} alt="investor_image" />
        ) : (
          <div className="no_avatar">{getAbbreviation(item.name)}</div>
        )}
        <div className="name_text">{item.name}</div>
      </div>
      <div className="investor_action" onClick={() => openMessgaeDialog()}>
        <img src={MessageIcon} alt="message_icon" />
      </div>
    </div>
  );
}
