// Common
import React from 'react';
import { useParams } from 'react-router';

// Componets
import ChatList from './chatList';
import Chat from './chat';

import './index.scss';

export default function Messages() {
  const { chat_id } = useParams();

  return (
    <div className="chat_wrapper">
      <ChatList chat_id={chat_id} />
      {chat_id && <Chat chat_id={chat_id} />}
    </div>
  );
}
