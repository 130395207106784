import React, { useState, useEffect, useCallback } from 'react';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import history from '../../history';
import SessionContainer from './session_container';
import {
  forgotPassword,
  sendUserCode,
  verifyResetPass,
  verifyUser,
} from '../../services/user';
import { useLocation } from 'react-router-dom';
import './index.scss';

function Verification() {
  const [verfCode, setVerfCode] = useState(['', '', '', '', '', '']);
  const [activeButton, setActiveButton] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isErr, setIsErr] = useState(false);
  const [isResetPass, setIsResetPass] = useState(false);

  const location = useLocation();

  const handleChange = (event, key) => {
    const newValue = event.target.value;

    if (Number.isInteger(+newValue)) {
      setVerfCode(
        verfCode.map((element, index) => (key === index ? newValue : element)),
      );

      if (newValue !== '') {
        focusNextElement(key);
      }
    }
  };

  const focusNextElement = (key) => {
    const isLast = key === 5;

    if (isLast) {
      const submitBtn = document.getElementById('log-in-bnt');
      submitBtn.focus();
      return;
    }

    const nextElement = document.getElementById(`code-${parseInt(key) + 1}`);

    if (nextElement) {
      nextElement.focus();
    }
  };

  const focusPrevElement = (key) => {
    const isFirst = key === 0;

    if (isFirst) {
      return;
    }

    const nextElement = document.getElementById(`code-${parseInt(key) - 1}`);

    if (nextElement) {
      nextElement.focus();
    }
  };

  const handleKeyPress = (event) => {
    const isBackSpaceKey = event.keyCode === 8;

    if (isBackSpaceKey && event.target.value === '') {
      const focusKey = event.target.id.slice(-1);
      focusPrevElement(focusKey);
    }
  };

  // verify sign in
  const handleSubmit = useCallback(() => {
    let sum = '';

    verfCode.forEach((num) => {
      sum += num;
    });

    if (sum.length > 0) {
      setLoading(true);
      verifyUser(sum)
        .then((res) => {
          setLoading(false);
          history.push('/dashboard');
        })
        .catch((err) => {
          setIsErr(true);
          setLoading(false);
        });
    }
  }, [verfCode]);

  const handleReSend = useCallback((e) => {
    setLoading(true);
    sendUserCode()
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  // verify reset pass
  const handleVerifyReset = useCallback(() => {
    let sum = '';

    verfCode.forEach((num) => {
      sum += num;
    });

    if (sum.length > 0) {
      const token = localStorage.getItem('moneyflow-reset-password-token');

      setLoading(true);
      verifyResetPass(sum, token)
        .then(() => {
          setLoading(false);
          history.push(`/password_reset/${token}`);
        })
        .catch((err) => {
          setIsErr(true);
          setLoading(false);
        });
    }
  }, [verfCode]);

  const handleResetPassResend = useCallback((e) => {
    const email = localStorage.getItem('moneyflow-reset-password-email');
    setLoading(true);
    forgotPassword(email)
      .then((res) => {
        // updating reset token
        localStorage.setItem('moneyflow-reset-password-token', res.data.token);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, []);

  const handleFocus = () => {
    // remove err if exist
    if (isErr) {
      setIsErr(false);
    }
  };

  useEffect(() => {
    if (verfCode.every((el) => el !== '')) {
      setActiveButton(false);
    }

    return () => {
      setActiveButton(true);
    };
  }, [verfCode]);

  useEffect(() => {
    const pasteHandler = (event) => {
      let pastedText = (event.clipboardData || window.clipboardData).getData(
        'text',
      );
      const isValid = /^[0-9]*$/.test(pastedText);

      if (pastedText?.length === 6 && isValid) {
        setVerfCode([
          pastedText[0],
          pastedText[1],
          pastedText[2],
          pastedText[3],
          pastedText[4],
          pastedText[5],
        ]);
      }
    };

    document.addEventListener('paste', pasteHandler);

    return () => {
      document.removeEventListener('paste', pasteHandler);
    };
  }, []);

  useEffect(() => {
    if (location.pathname.match('password_forgot')) {
      setIsResetPass(true);
    }
  }, [location]);

  return (
    <SessionContainer>
      <Grid item>
        <Typography variant="h1" className="verification-title">
          Verify your account
        </Typography>
        <Typography variant="subtitle2">
          Please, type in the verification number sent to your email.
        </Typography>
      </Grid>
      <Grid container className="verification-container">
        {verfCode.map((code, key) => (
          <Grid item xs={2} key={`code-${key}`}>
            <TextField
              error={isErr}
              id={`code-${key}`}
              variant="outlined"
              value={code}
              name="code"
              onChange={(event) => handleChange(event, key)}
              onKeyDown={handleKeyPress}
              onFocus={handleFocus}
              inputProps={{ maxLength: 1 }}
              sx={{ border: 3 }}
              type="text"
              className="verification-input"
            />
          </Grid>
        ))}
      </Grid>

      {loading ? (
        <div className="loader-container">
          <CircularProgress />
        </div>
      ) : (
        <>
          <Button
            onClick={isResetPass ? handleVerifyReset : handleSubmit}
            id="log-in-bnt"
            size="large"
            color="primary"
            variant="contained"
            className="verification-create-btn"
            disabled={activeButton}
          >
            {isResetPass ? 'Verify' : 'Create account'}
          </Button>
          <Button
            onClick={isResetPass ? handleResetPassResend : handleReSend}
            color="primary"
            variant="contained"
            className="verification-resend-btn"
          >
            Resend
          </Button>
        </>
      )}
    </SessionContainer>
  );
}

export default Verification;
