// Common
import React, { useEffect } from 'react';
import { Router, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import history from './history';
import store from './store';
import theme from './schema';

// Action
import { check } from './actions/user';

// Requests
// import { getTranslation } from './services/translation';

// Components
import SignUp from './components/session/sign_up';
import LogIn from './components/session/login';
import PasswordForgot from './components/session/password_forgot/index';
// import PasswordForgotModal from './components/session/password_forgot/password_forgot_modal';
import PasswordReset from './components/session/password_reset/index';
import { Header } from './components/common/header/index';
import Dashboard from './components/dashboard/index';
import Basics from './components/project/basics/index';
import Video from './components/project/video';
import Description from './components/project/descriptyion';
import Images from './components/project/images';
import Docs from './components/project/docs';
import Investments from './components/project/investments';
import Summary from './components/project/summary/index';
import BisinessModel from './components/project/bisinessModel';
import Messages from './components/messages';
import Profile from './components/profile';
import EditProfile from './components/profile/edit_profile';
import Preview from './components/preview';
import Verification from './components/session/verification';
import './App.scss';

function ConnectTranslate({ children }) {
  const userChecked = useSelector((state) => state.user.userChecked);
  const dispatch = useDispatch();
  const isPreview = window.location.pathname.includes('preview');

  useEffect(() => {
    if (!isPreview) {
      const session_token = localStorage.getItem('session_token');
      // no credentials
      // getTranslation('en').then(() => {});

      dispatch(check(session_token));
    }
  }, [dispatch, isPreview]);

  return userChecked || isPreview ? (
    <React.Fragment>{children}</React.Fragment>
  ) : (
    <></>
  );
}

const withHeader = (Comp) => (props) => {
  const { path } = useRouteMatch();
  return (
    <React.Fragment>
      {path === '/profile/edit' || path === '/project/bisiness_model/:id' ? (
        <>
          <Header />
          <Comp {...props} />
        </>
      ) : (
        <Header>
          <Comp {...props} />
        </Header>
      )}
    </React.Fragment>
  );
};

function App() {
  return (
    <div>
      <Provider store={store}>
        <ConnectTranslate>
          <ThemeProvider theme={theme}>
            <Router history={history}>
              <Switch>
                <Route exact path="/sign_up" component={SignUp} />
                <Route exact path="/login" component={LogIn} />
                <Route path="/sign_up/verification" component={Verification} />
                <Route
                  exact
                  path="/password_forgot"
                  component={PasswordForgot}
                />
                <Route
                  exact
                  path="/password_forgot/verification"
                  component={Verification}
                />
                <Route
                  exact
                  path="/password_reset/:id"
                  component={PasswordReset}
                />
                <Route path="/dashboard" component={withHeader(Dashboard)} />
                <Route
                  exact
                  path="/messages"
                  component={withHeader(Messages)}
                />
                <Route
                  exact
                  path="/messages/:chat_id"
                  component={withHeader(Messages)}
                />
                <Route exact path="/profile" component={withHeader(Profile)} />
                <Route
                  path="/profile/edit"
                  component={withHeader(EditProfile)}
                />
                <Route
                  path="/project/basics/:id"
                  component={withHeader(Basics)}
                />
                <Route
                  path="/project/basics/new"
                  component={withHeader(Basics)}
                />
                <Route
                  path="/project/description/:id"
                  component={withHeader(Description)}
                />
                <Route
                  path="/project/description/new"
                  component={withHeader(Description)}
                />
                <Route
                  path="/project/video/:id"
                  component={withHeader(Video)}
                />
                <Route
                  path="/project/video/new"
                  component={withHeader(Video)}
                />
                <Route
                  path="/project/images/:id"
                  component={withHeader(Images)}
                />
                <Route
                  path="/project/images/new"
                  component={withHeader(Images)}
                />
                <Route path="/project/docs/:id" component={withHeader(Docs)} />
                <Route path="/project/docs/new" component={withHeader(Docs)} />
                <Route
                  path="/project/investments/:id"
                  component={withHeader(Investments)}
                />
                <Route
                  path="/project/investments/new"
                  component={withHeader(Investments)}
                />
                <Route
                  path="/project/summary/:id"
                  component={withHeader(Summary)}
                />
                <Route
                  path="/project/summary/new"
                  component={withHeader(Summary)}
                />
                <Route
                  path="/project/bisiness_model/:id"
                  component={withHeader(BisinessModel)}
                />
                <Route
                  path="/project/bisiness_model/new"
                  component={withHeader(BisinessModel)}
                />

                <Route path="/preview/:id" component={Preview} />
              </Switch>
            </Router>
          </ThemeProvider>
        </ConnectTranslate>
      </Provider>
    </div>
  );
}

export default App;
