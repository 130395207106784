import {
  SET_ERRORS,
  SET_ERROR,
  CLEAR_ERRORS,
  CLEAR_ERROR,
} from '../actions/errors';

const initialState = {};

const errorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ERRORS:
      return (state = action.errors);
    case SET_ERROR:
      return (state = Object.assign(state, action.error));
    case CLEAR_ERRORS:
      return (state = {});
    case CLEAR_ERROR:
      return delete state[action.param];
    default:
      return state;
  }
};

export default errorsReducer;
