// Common
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useValidation } from '../../hooks/useValidation';

// Components
import { TextField, Typography } from '@material-ui/core';

// Icons
import ErrorIcon from '../../../images/icons_error.svg';
import ErrorIconBlue from '../../../images/icons_error_blue.svg';

// Styles
import './index.scss';

export function Multiline({
  label,
  value,
  name,
  onChange,
  validation,
  formHelperText,
}) {
  const [showError, setShowError] = useState(false);
  useValidation(value, name, validation && showError);
  const error = useSelector((state) => state.errors[name]);

  const renderError = () => {
    return (
      showError &&
      error &&
      formHelperText && (
        <div className="multiple_formHelper">
          <div className="error_text">
            <img src={error ? ErrorIcon : ErrorIconBlue} alt="error_icon" />
            <Typography color={error ? 'error' : 'primary'}>
              {error ? error : formHelperText}
            </Typography>
          </div>
          <div className="value_counter">{value.length}</div>
        </div>
      )
    );
  };

  return (
    <div className="input_wrapper">
      <TextField
        error={!!error && showError && value}
        fullWidth
        multiline
        rows={7}
        label={label}
        type="text"
        value={value}
        name={name}
        onChange={onChange}
        variant="outlined"
        FormHelperTextProps={{ component: 'div' }}
        helperText={value && renderError()}
        onBlur={() => setShowError(true)}
        onFocus={() => setShowError(false)}
        disabled={!onChange}
      />
    </div>
  );
}
