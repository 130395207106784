import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import history from './history';

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error?.response?.status) {
      if (
        window.location.pathname.search('sign_up') === -1 &&
        window.location.pathname.search('password_forgot') === -1 &&
        window.location.pathname.search('password_reset') === -1 &&
        window.location.pathname.search('preview') === -1
      ) {
        history.push('/login');
      }
    }
    return Promise.reject(error);
  },
);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
