import { useSelector, useDispatch } from 'react-redux';
import { handleChnageProjectStatuses } from '../../actions/projects';

export const useProjectStatuses = () => {
  const dispatch = useDispatch();
  const errors = useSelector((state) => state.errors);
  const {
    type,
    title,
    shortDescription,
    longDescription,
    location,
    currency,
    category,
    ownerCompany,
    mainImage,
    logo,
    additionalImages,
    documents,
    investmentNeeds,
    publishType,
    stages,
    investmentTypes,
    status,
    businessModel: {
      customerSegment,
      valuePropositions,
      channels,
      customerRelationships,
      keyResources,
      keyActivities,
      keyPartners,
      costStructure,
      revenueStreams: { types, fixedPricing, dynamicPricing },
    },
  } = useSelector((state) => state.projects.selected);

  const stepFeilds = {
    bisiness_model: [
      {
        key: 'customerSegment',
        value: customerSegment.every((item) => item !== ''),
      },
      {
        key: 'valuePropositions',
        value: valuePropositions.every((item) => item !== ''),
      },
      { key: 'channels', value: channels.every((item) => item !== '') },
      {
        key: 'customerRelationships',
        value: customerRelationships.every((item) => item !== ''),
      },
      { key: 'keyResources', value: keyResources.every((item) => item !== '') },
      {
        key: 'keyActivities',
        value: keyActivities.every((item) => item !== ''),
      },
      { key: 'keyPartners', value: keyPartners.every((item) => item !== '') },
      {
        key: 'costStructure',
        value: costStructure.every((item) => item !== ''),
      },
      { key: 'types', value: types.every((item) => item !== '') },
      { key: 'fixedPricing', value: fixedPricing.every((item) => item !== '') },
      {
        key: 'dynamicPricing',
        value: dynamicPricing.every((item) => item !== ''),
      },
    ],
    basicStep1: [
      { key: 'type', value: type },
      { key: 'location', value: location },
      { key: 'currency', value: currency },
      { key: 'category', value: category },
    ],
    basicStep2: [
      { key: 'name', value: ownerCompany.name },
      { key: 'address', value: ownerCompany.address },
      { key: 'zip', value: ownerCompany.zip },
      { key: 'city', value: ownerCompany.city },
      { key: 'email', value: ownerCompany.email },
      { key: 'mobile', value: ownerCompany.mobile },
      { key: 'countryCode', value: ownerCompany.countryCode },
    ],
    description: [
      { key: 'title', value: title },
      { key: 'shortDescription', value: shortDescription },
      { key: 'longDescription', value: longDescription },
    ],
    images: [
      { key: 'mainImage', value: mainImage },
      { key: 'logo', value: logo },
      { key: 'additionalImages', value: !!additionalImages.length },
    ],
    docs: [{ key: 'documents', value: !!documents.length }],
    investments: [
      { key: 'number', value: investmentNeeds.number },
      { key: 'sharesNumber', value: investmentNeeds.sharesNumber },
      { key: 'price', value: investmentNeeds.price },
      { key: 'budget', value: investmentNeeds.budget },
      { key: 'stages', value: stages },
      { key: 'investmentTypes', value: !!investmentTypes.length },
    ],
    summary: [{ key: 'publishType', value: publishType }],
  };

  const steps = Object.keys(stepFeilds);

  let statuses = {
    basicStep1: null,
    basicStep2: null,
    description: null,
    video: null,
    images: null,
    docs: null,
    investments: null,
    summary: null,
    bisiness_model: null,
  };

  steps.forEach((step) => {
    let filter = stepFeilds[step].filter((field) => !field.value);

    if (step === 'investments' && filter.length === 1) {
      filter = filter.filter((field) => field?.value !== 0);
    }
    let ready = !filter.length;

    stepFeilds[step].forEach((field) => {
      if (errors[field.key]) {
        ready = false;

        if (step === 'description' && errors.title && title) {
          ready = true;
        }
      }
    });

    if (ready) {
      statuses[step] = 'Done';
      if (step === 'summary' && !setSummaryStatus(statuses)) {
        statuses[step] = 'Not Ready';
      }
      if (step === 'summary' && setSummaryStatus(statuses) && !status) {
        statuses[step] = 'Ready to send';
      }
      if (step === 'summary' && setSummaryStatus(statuses) && status) {
        statuses[step] = status;
      }
    } else {
      statuses[step] = 'Not Ready';
    }
  });

  dispatch(handleChnageProjectStatuses(statuses));

  return statuses;
};

const setSummaryStatus = (statuses) => {
  const {
    basicStep1,
    basicStep2,
    description,
    video,
    images,
    docs,
    investments,
    bisiness_model,
  } = statuses;

  const statusArr = [
    basicStep1,
    basicStep2,
    description,
    video,
    images,
    docs,
    investments,
    bisiness_model,
  ];

  if (statusArr.find((step) => step === 'Not Ready')) {
    return false;
  }
  return true;
};

export default useProjectStatuses;
