import { combineReducers } from 'redux';

import translation from './translation';
import user from './user';
import errors from './errors';
import projects from './projects';
import projectStatuses from './projectStatuses';
import messages from './messages';

const rootReducer = combineReducers({
  translation,
  user,
  errors,
  projects,
  projectStatuses,
  messages,
});

export default rootReducer;
