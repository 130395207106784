// Common
import { useState, useEffect } from 'react';
import { showFile } from '../../../services/user';

//Components
import { CircularProgress, Typography } from '@material-ui/core';

// Images
import UploadIcons from '../../../images/icons_upload_dark.svg';
import CloseIcon from '../../../images/icons_close_white.svg';
import ErrorIcon from '../../../images/icons_error.svg';

// Styles
import './index.scss';

export default function ImageUploader({
  name,
  imageUrl,
  onChange,
  onDestroy,
  imageUploading,
  isTeamImg = false,
}) {
  const [image, setImage] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (imageUrl) {
      showFile(imageUrl).then((res) => setImage(res.data));
    }
    return () => setImage('');
  }, [imageUrl]);

  const destroyImage = () => {
    onDestroy();
    setImage('');
  };

  const validateImage = (event) => {
    const isNotJpeg = event?.target?.files[0].type !== 'image/jpeg';
    const isNotPng = event?.target?.files[0].type !== 'image/png';

    if (isNotJpeg && isNotPng) {
      setError('Image should be png or jpeg.');
      return;
    }
    event.preventDefault();
    setError('');
    onChange(event);
  };

  const preventDefault = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const dt = event.dataTransfer;
    const e = { target: { files: dt.files } };
    onChange(e);
    event.stopPropagation();
  };

  return (
    <form>
      {imageUrl ? (
        <div
          className={
            !isTeamImg ? 'show_image_wrapper' : 'show_team_image_wrapper'
          }
        >
          <img src={image} alt="logo" />
          {!!onDestroy && (
            <div className="delete_image_button" onClick={destroyImage}>
              <img src={CloseIcon} alt="close_icon" />
            </div>
          )}
        </div>
      ) : (
        <div
          className={
            !isTeamImg ? 'upload_image_wrapper' : 'upload_team_image_wrapper'
          }
          onDrop={handleDrop}
          onDragEnter={preventDefault}
          onDragOver={preventDefault}
          onDragLeave={preventDefault}
        >
          {imageUploading ? (
            <CircularProgress />
          ) : (
            <img src={UploadIcons} alt="upload_icons_dark" />
          )}

          <div className="upload_image-large_text">Drag and drop an image</div>
          <div className="upload_image-small_text">
            or{' '}
            <span
              className="upload_button"
              onClick={() => {
                document.getElementById('upload_input').click();
              }}
            >
              browse
            </span>{' '}
            to choose a file
          </div>
          <input
            id="upload_input"
            type="file"
            accept=".jpg,.png"
            name={name}
            onChange={validateImage}
          />
        </div>
      )}
      {error && (
        <div className="error_text">
          <img src={ErrorIcon} alt="error_icon" />
          <Typography color="error">{error}</Typography>
        </div>
      )}
    </form>
  );
}
