// Images
import CheckIcon from '../../../images/icons_check_staus.svg';
import AtentionIcon from '../../../images/icons_atention.svg';
import RejectIcon from '../../../images/icons_reject.svg';

// Styles
import './index.scss';

export default function StatusLabel({ status }) {
  const checkColor = () => {
    if (
      status === 'Done' ||
      status === 'Approved' ||
      status === 'Ready to send' ||
      status === 'Published'
    ) {
      return '#41dc6c';
    }
    if (
      status === 'Not Ready' ||
      status === 'Waiting for Review' ||
      status === 'In review'
    ) {
      return '#fdc22a';
    }
    if (status === 'Rejected') {
      return '#f15642';
    }
  };

  const checkIcon = () => {
    if (
      status === 'Done' ||
      status === 'Approved' ||
      status === 'Ready to send' ||
      status === 'Published'
    ) {
      return CheckIcon;
    }
    if (
      status === 'Not Ready' ||
      status === 'Waiting for Review' ||
      status === 'In review'
    ) {
      return AtentionIcon;
    }
    if (status === 'Rejected') {
      return RejectIcon;
    }
  };

  return status ? (
    <div
      className="status_label_wrapper"
      style={{ backgroundColor: checkColor() }}
    >
      <img src={checkIcon()} alt="status_icon" />
      {status}
    </div>
  ) : (
    <></>
  );
}
