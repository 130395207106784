import { Typography, Box, withStyles, Button } from '@material-ui/core';
import { useCallback, useEffect, useRef, useState } from 'react';
import { deleteAvatar } from '../../../actions/user';
import DeleteIcon from '../../../images/icons_Delete_white.svg';
import { showFile } from '../../../services/user';
import { useDispatch } from 'react-redux';
import { getAbbreviation } from '../abbreviation';

const UploadButton = withStyles({
  root: {
    marginLeft: '28px',
    width: '198px',
    height: '44px',
    background: '#62a4cd',
    color: '#ffffff',
    fontFamily: 'SFProDisplay-Medium',
    fontSize: '16px',
    '&:hover': {
      background: '#62a4cd',
    },
  },
})(Button);

const DeleteButton = withStyles({
  root: {
    marginLeft: '33px',
    width: '141px',
    height: '44px',
    background: '#c7c7c7',
    color: '#ffffff',
    fontFamily: 'SFProDisplay-Medium',
    fontSize: '16px',
    '&:hover': {
      background: '#c7c7c7',
    },
  },
})(Button);

const AploadPhotos = ({ setInputFile, picture, id, name }) => {
  const dispatch = useDispatch();
  const [Img, setImg] = useState(null);
  const fileInput = useRef(null);
  const DeleteAvater = useCallback(() => {
    dispatch(deleteAvatar(id, [Img.split('/')[3]]));
  }, [id, Img, dispatch]);

  const handlerOn = (e) => {
    const file = e.target.files[0];
    setInputFile(file);
  };

  useEffect(() => {
    if (picture !== null && picture !== undefined) {
      showFile(picture).then((res) => setImg(res.data));
    }
  }, [picture]);

  return (
    <Box
      width="100%"
      mt="48px"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        width="190px"
        height="190px"
        bgcolor="#f2f2f2"
        display="flex"
        alignItems="center"
        justifyContent="center"
        fontSize="50px"
        borderRadius="50%"
      >
        {picture === null || picture === undefined ? (
          <Typography variant="body2">{getAbbreviation(name)}</Typography>
        ) : (
          <img
            style={{
              borderRadius: '50%',
              width: '190px',
              height: '190px',
              objectFit: 'cover',
            }}
            alt=""
            src={Img}
          />
        )}
      </Box>

      <input
        style={{ display: 'none' }}
        ref={fileInput}
        accept=".png, .jpg, .jpeg"
        onChange={handlerOn}
        type="file"
      />

      <UploadButton
        onClick={(e) => fileInput.current && fileInput.current.click()}
      >
        Upload new picture
      </UploadButton>

      <DeleteButton onClick={DeleteAvater}>
        <img
          style={{ marginRight: '8px' }}
          width="24px"
          height="24px"
          alt=""
          src={DeleteIcon}
        />
        Delete
      </DeleteButton>
    </Box>
  );
};

export default AploadPhotos;
