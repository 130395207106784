// Common
import { useState, useEffect } from 'react';
import { showFile } from '../../../services/user';

//Components
import { CircularProgress } from '@material-ui/core';

// Images
import UploadIcons from '../../../images/icons_upload_dark.svg';
import CloseIcon from '../../../images/icons_close_white.svg';
// import ErrorIcon from '../../../images/icons_error.svg'
import DocIcon from '../../../images/documents_doc_icon.svg';

// Styles
import './index.scss';

export default function DocUploader({
  name,
  docUrl,
  onChange,
  onDestroy,
  docUploading,
}) {
  const [doc, setDoc] = useState('');
  // const [error, setError] = useState('')

  useEffect(() => {
    if (docUrl) {
      showFile(docUrl.url).then((res) => setDoc(res.data));
    }
    return () => setDoc('');
  }, [docUrl]);

  const destroyDoc = () => {
    onDestroy();
    setDoc('');
  };

  const validateDoc = (event) => {
    event.preventDefault();
    onChange(event);
  };

  const preventDefault = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const dt = event.dataTransfer;
    const e = { target: { files: dt.files } };
    onChange(e);
    event.stopPropagation();
  };

  return (
    <div>
      {doc ? (
        <div className="show_doc_wrapper">
          {onDestroy && (
            <div className="delete_image_button" onClick={destroyDoc}>
              <img src={CloseIcon} alt="close_icon" />
            </div>
          )}
          <img src={DocIcon} alt="doc_icon" />
          <div className="docs_name">{docUrl.name + '.' + docUrl.type}</div>
        </div>
      ) : (
        <div
          className="upload_doc_wrapper"
          onDrop={handleDrop}
          onDragEnter={preventDefault}
          onDragOver={preventDefault}
          onDragLeave={preventDefault}
        >
          {docUploading ? (
            <CircularProgress />
          ) : (
            <img src={UploadIcons} alt="upload_icons_dark" />
          )}

          <div className="upload_doc-large_text">
            Drag to Upload PDF / .docs
          </div>
          <div className="upload_doc-small_text">
            or{' '}
            <span
              className="upload_button"
              onClick={() => document.getElementById('upload_input').click()}
            >
              browse
            </span>{' '}
            to choose a file
          </div>
          <input
            id="upload_input"
            type="file"
            name={name}
            onChange={validateDoc}
          />
        </div>
      )}
      {/* {error && (
        <div className="error_text">
          <img src={ErrorIcon} alt="error_icon"/>
          <Typography color="error">{error}</Typography>
        </div>
      )} */}
    </div>
  );
}
