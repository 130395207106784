import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import ReviewHistoryItem from './reviewHistoryItem';

// Action
import { historyList } from '../../../actions/projects';

import '../index.scss';

export default function ReviewHistory() {
  const reviewHistory = useSelector(
    (state) => state.projects.selected.reviewHistory,
  );
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(historyList(id));
  }, [id, dispatch]);

  useEffect(() => {
    const setHorizontalHeight = () => {
      const topElement = document.getElementById(reviewHistory[0]._id);
      const bottomElement = document.getElementById(
        reviewHistory[reviewHistory.length - 1]._id,
      );
      const parentElement = document.getElementById('history_container');
      let line = document.getElementById('horizontal_line');
      const marginTop = topElement.offsetHeight / 2;
      const marginBottom = bottomElement.offsetHeight / 2;
      const height = parentElement.offsetHeight - marginTop - marginBottom - 48;
      line.style.minHeight = `${height}px`;
      line.style.marginTop = `${marginTop + 48}px`;
      line.style.marginBottom = `${marginBottom}px`;
      line.style.left = `${topElement.offsetLeft - 60}px`;
    };

    if (reviewHistory.length) setHorizontalHeight();
  }, [reviewHistory]);

  return (
    <div className="history_container" id="history_container">
      <div className="horizontal_line" id="horizontal_line" />
      {reviewHistory.map((item) => (
        <ReviewHistoryItem key={item._id} item={item} />
      ))}
    </div>
  );
}
