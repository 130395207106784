import React from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const countryCodes = require('country-codes-list');

const countryCodesList = countryCodes.customArray({
  value: '{countryNameEn} (+{countryCallingCode})',
  code: '+{countryCallingCode}',
});

function CountryCode({ onChange, value, isSignUp = false }) {
  return (
    <Autocomplete
      name="autoComplete"
      value={countryCodesList.find((item) => item.value === value)}
      onChange={(event, newValue) => {
        let result = newValue ? newValue.value : '';

        // sending other format for sign up
        if (isSignUp) {
          result = newValue ? newValue.code : '';
        }

        onChange({
          target: {
            name: 'countryCode',
            value: result,
          },
        });
      }}
      options={countryCodesList}
      getOptionSelected={(option, value) => option.label === value?.value}
      getOptionLabel={(option) => option.value}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Country code"
          name="autoCompleteInput"
          variant="outlined"
        />
      )}
    />
  );
}

export default CountryCode;
