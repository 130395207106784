export const SET_ERRORS = 'USER/SET_ERRORS';
export const SET_ERROR = 'USER/SET_ERROR';
export const CLEAR_ERRORS = 'USER/CLEAR_ERRORS';
export const CLEAR_ERROR = 'USER/CLEAR_ERROR';

export const setError = (error) => {
  return {
    type: SET_ERROR,
    error: error,
  };
};

export const setErrors = (errors) => {
  return {
    type: SET_ERRORS,
    errors,
  };
};

export const clearErrors = () => {
  return {
    type: CLEAR_ERRORS,
  };
};

export const clearError = (param) => {
  return {
    type: CLEAR_ERROR,
    param,
  };
};
