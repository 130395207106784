import React from 'react';
import SessionContainer from '../session_container';
import { Grid, Typography, Button, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';

export const LinkStyle = { textDecoration: 'none' };

export default function PasswordForgotModal() {
  return (
    <SessionContainer>
      <Grid item>
        <Typography variant="h1">Check your email</Typography>
        <Box mb="40px">
          <Typography variant="subtitle2">
            We have sent a password recover instructions to your email
          </Typography>
        </Box>
      </Grid>
      <Grid item>
        <Link
          style={LinkStyle}
          to="#"
          onClick={(e) => {
            window.location = 'mailto:';
            e.preventDefault();
          }}
        >
          <Button size="large" color="primary" variant="contained">
            Open email box
          </Button>
        </Link>
      </Grid>
    </SessionContainer>
  );
}
