// Common
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';

// Components
import { Typography, Box, Grid } from '@material-ui/core';
import ImageUploader from '../common/imageUploader/index';
import { TooltipComponent } from '../common/tooltip/index';
import NextBackButtons from './nextBackButtons';

// Actions
import {
  uploadMainImage,
  uploadLogo,
  uploadAdditionalImage,
  removeImage,
} from '../../actions/projects';

// Styles
import './index.scss';

export default function Images() {
  const { logo, mainImage, additionalImages } = useSelector(
    (state) => state.projects.selected,
  );
  const imageUploading = useSelector((state) => state.projects.uploadingFile);
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const selectLogo = (event) => {
    dispatch(uploadLogo(event.target.files[0], id));
  };

  const selectMainImage = (event) => {
    dispatch(uploadMainImage(event.target.files[0], id));
  };

  const selectAdditionalImage = (event) => {
    dispatch(uploadAdditionalImage(event.target.files[0], id));
  };

  return (
    <div className="project_form_container">
      <Typography variant="h2">Graphics, images and presentations</Typography>
      <Box mt="10px">
        <Typography variant="subtitle2">
          On this page you can upload assets like logo and images / mockups
        </Typography>
      </Box>
      <Box mt="48px" maxWidth="450px">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h4">Upload logo</Typography>
          <TooltipComponent text="Logo image should be 600x150 px in .png with trasparent background" />
        </div>
      </Box>
      <Box mt="24px" mb="32px">
        <ImageUploader
          name="logo"
          onChange={selectLogo}
          imageUrl={logo}
          onDestroy={() =>
            dispatch(
              removeImage(
                id,
                [
                  logo.replace(
                    'https://moneyflow-back.s3.us-east-2.amazonaws.com/',
                    '',
                  ),
                ],
                'logo',
              ),
            )
          }
          imageUploading={imageUploading}
        />
      </Box>
      <div className="underline" />
      <Box mt="48px" maxWidth="450px">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h4">Upload images</Typography>
          <TooltipComponent text="The images should at least 1920x1080 and in .jpg" />
        </div>
      </Box>
      <Box mt="48px" mb="48px">
        <Grid
          container
          wrap="nowrap"
          alignItems={'flex-start'}
          justify={'flex-start'}
          spacing={6}
          xl
        >
          <Grid item>
            <Typography variant="h4">Main image</Typography>
            <br />
            <ImageUploader
              name="mainImage"
              onChange={selectMainImage}
              imageUrl={mainImage}
              onDestroy={() =>
                dispatch(
                  removeImage(
                    id,
                    [
                      mainImage.replace(
                        'https://moneyflow-back.s3.us-east-2.amazonaws.com/',
                        '',
                      ),
                    ],
                    'mainImage',
                  ),
                )
              }
              imageUploading={imageUploading}
            />
          </Grid>
          <Grid item container md>
            <Grid item>
              <Typography variant="h4">Additional images</Typography>
              <br />
              <Grid container spacing={6}>
                {additionalImages.map((image, index) => (
                  <Grid item key={index}>
                    <ImageUploader
                      name="additionalImage"
                      onChange={selectAdditionalImage}
                      imageUrl={image}
                      onDestroy={() =>
                        dispatch(
                          removeImage(
                            id,
                            [
                              image.replace(
                                'https://moneyflow-back.s3.us-east-2.amazonaws.com/',
                                '',
                              ),
                            ],
                            'additionalimages',
                          ),
                        )
                      }
                      imageUploading={imageUploading}
                    />
                  </Grid>
                ))}
                <Grid item>
                  <ImageUploader
                    name="mainImage"
                    onChange={selectAdditionalImage}
                    imageUrl={null}
                    // onDestroy={() => dispatch(removeImage(id, [logo.replace('https://moneyflow-back.s3.us-east-2.amazonaws.com/', '')]))}
                    imageUploading={imageUploading}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <NextBackButtons
        onClickNext={() => history.push(`/project/docs/${id}`)}
        onClickBack={() => history.push(`/project/video/${id}`)}
        disabledNext={!(logo && mainImage && additionalImages.length)}
      />
    </div>
  );
}
