import { Button, withStyles } from '@material-ui/core';

export const SaveBMButton = withStyles({
  root: {
    fontSize: '16px',
    fontFamily: 'SFProDisplay-Medium',
    textTransform: 'none',
    marginTop: '20px',
    float: 'right',
    width: '12%',
    height: '100%',
    background: '#62a4cd',
    color: '#ffffff',
    '&:hover': {
      background: '#62a4cd',
    },
    '&:disabled': {
      background: '#e1f0fa',
      color: '#ffffff',
    },
  },
})(Button);

export const AddBMButton = withStyles({
  root: {
    textTransform: 'none',
    fontSize: '16px',
    fontFamily: 'SFProDisplay-Medium',
    width: '80px',
    height: '30px',
    background: '#62a4cd',
    color: '#ffffff',
    '&:hover': {
      background: '#62a4cd',
    },
  },
})(Button);

// export const useStylesTooltip = makeStyles((theme) => ({
//   arrow: {
//     color: '#62a4cd',
//     top: '27px',
//   },

//   tooltip: {
//     backgroundColor: '#62a4cd',
//     fontSize: '15px',
//     textAlign: 'center',
//     borderColor: '#62a4cd',
//   },
// }));
