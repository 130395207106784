import {
  login,
  register,
  checkUser,
  editProfile,
  UploadAvatarImg,
  DeleteCurrentAvatar,
} from '../services/user';

import history from '../history';
import { SET_ERRORS, CLEAR_ERRORS } from './errors';
export const SET_USER = 'USER/SET_USER';
export const SET_CHECK_USER = 'USER/SET_CHECK_USER';

export const registerRequest = (data) => {
  return (dispatch) => {
    register(data)
      .then((res) => {
        localStorage.setItem('session_token', res.data.token);
        dispatch({ type: CLEAR_ERRORS });
        dispatch({ type: SET_USER, user: res.data.user });
        history.push('/sign_up/verification');
      })
      .catch((res) => {
        const error = res.response.data;
        if (error.message) {
          const isEmailError = error.message.search(/email/);
          const isPhoneError = error.message.search(/phone/);

          if (isEmailError !== -1)
            dispatch({
              type: SET_ERRORS,
              errors: {
                email: 'Email is already used. Please, use the new email',
              },
            });
          if (isPhoneError !== -1)
            dispatch({
              type: SET_ERRORS,
              errors: {
                phone:
                  'Mobile number is already used. Please, use the new mobile number',
              },
            });
        }
      });
  };
};

export const loginRequest = (data) => {
  return (dispatch) => {
    login(data)
      .then((res) => {
        localStorage.setItem('session_token', res.data.token);
        localStorage.setItem('owner_id', res.data.user._id);
        dispatch({ type: CLEAR_ERRORS });
        dispatch({ type: SET_USER, user: res.data.user });
        history.push('/dashboard');
      })
      .catch((error) => {
        dispatch({
          type: SET_ERRORS,
          errors: {
            phone: 'Incorrect Phone number or Password',
            password: error.message,
          },
        });
      });
  };
};

export const check = (session_token) => {
  return (dispatch) => {
    dispatch({ type: SET_CHECK_USER, userChecked: false });
    checkUser(session_token)
      .then((res) => {
        [/\b[/]/, /\b[/login]/, /\b[/sign_up]/, /\b[/password_forgot]/].forEach(
          (route) => {
            if (!route.test(window.location.pathname)) {
              history.push('/dashboard');
            }
          },
        );
        localStorage.setItem('owner_id', res.data._id);
        dispatch({ type: SET_USER, user: res.data });
        dispatch({ type: SET_CHECK_USER, userChecked: true });
      })
      .catch((error) => {
        [
          /[/]/,
          /\b[/sign_up]/,
          /\b[/password_forgot]/,
          /\b[/password_reset]/,
        ].forEach((route) => {
          if (!route.test(window.location.pathname)) {
            history.push('/login');
          }
        });
      });
    dispatch({ type: SET_CHECK_USER, userChecked: true });
  };
};

export const editProfButton = (userInfo, id, picture) => {
  return (dispatch) => {
    editProfile(userInfo, id, picture)
      .then((res) => {
        console.log('res.data:', res.data);
        dispatch({ type: SET_USER, user: res.data });
        history.push('/profile');
      })
      .catch((err) => {
        //
      });
  };
};

export const updateAvatar = (data) => {
  return (dispatch) => {
    UploadAvatarImg(data).then((res) =>
      dispatch({ type: SET_USER, user: res.data }),
    );
  };
};

export const deleteAvatar = (id, path) => {
  return (dispatch) => {
    DeleteCurrentAvatar(id, path).then((res) =>
      dispatch({ type: SET_USER, user: res.data }),
    );
  };
};
