// Common
import { useState, useEffect } from 'react';
import useLocalStorage from '../../hooks/useLocalStorage.js';
import { useHistory, useLocation } from 'react-router-dom';

// Component
import { AppBar, Grid, Tabs, Tab, Box } from '@material-ui/core';
import { ProfileMenu } from './meny.js';
import { ProjectDrawer } from '../projectDraver';

// Images
import Logo from '../../../images/logo.png';
import MenuIcon from '../../../images/menu_icon.svg';

// Style
import './index.scss';

export function Header({ children }) {
  const [openDrawer, setOpenDrawer] = useLocalStorage('open_drawer');
  const [value, setValue] = useState(0);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.match('dashboard')) {
      setValue('/dashboard');
    }
    if (location.pathname.match('messages')) {
      setValue('/messages');
    }
  }, [location]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    history.push(newValue);
  };

  return (
    <AppBar
      position="fixed"
      color="inherit"
      style={{ padding: '0 36px', zIndex: 1201 }}
    >
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        wrap="nowrap"
      >
        <Grid item>
          {location.pathname.match('project') && (
            <img
              className="menu_icon"
              src={MenuIcon}
              alt="menu_icon"
              onClick={() => setOpenDrawer(!openDrawer)}
            />
          )}
          <img className="logo" src={Logo} alt="logo" />
        </Grid>
        <Grid item>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="inherit"
            centered
            selectionFollowsFocus={false}
          >
            <Tab label="Dashboard" value="/dashboard" />
            <Tab label="Messages" value="/messages" />
            <Tab value={0} classes={{ root: 'none' }} />
          </Tabs>
        </Grid>
        <Grid item>
          <ProfileMenu />
        </Grid>
      </Grid>
      {location.pathname.match('project') && (
        <ProjectDrawer openDrawer={openDrawer} />
      )}
      {location.pathname.match('project') ? (
        <Box
          ml={openDrawer ? '40px' : '210px'}
          css={{ transition: 'margin-left cubic-bezier(0.4, 0, 0.6, 1) .195s' }}
        >
          {children}
        </Box>
      ) : (
        <Box>{children}</Box>
      )}
    </AppBar>
  );
}
