// Common
import { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import EditIcon from '../../images/edit_project_icon.svg';

// Actions
import { showFile } from '../../services/user';

// Styles
import './index.scss';

export default function ProjectItem({
  logo,
  title,
  shortDescription,
  status,
  onClickEditBtn,
  item,
}) {
  const [image, setImage] = useState('');
  const [isStatusVisible, setIsStatusVisible] = useState(true);

  const checkColor = () => {
    if (
      status === 'Done' ||
      status === 'Approved' ||
      status === 'Ready to send' ||
      status === 'Published'
    ) {
      return '#41dc6c';
    }
    if (
      status === 'Not Ready' ||
      status === 'Waiting for Review' ||
      status === 'In review'
    ) {
      return '#fdc22a';
    }
    if (status === 'Rejected') {
      return '#f15642';
    }
  };

  const isPublished = () => {
    return status === 'Published';
  };

  useEffect(() => {
    if (logo) {
      showFile(logo).then((res) => setImage(res.data));
    }
  }, [logo]);

  return (
    <div className="projects_item_wrapper">
      {image ? (
        <img className="projects_logo" src={image} alt="logo" />
      ) : (
        <div className="project_no_logo">{title ? title[0] : ''}</div>
      )}

      <div className="projects_item-info">
        <div className="project_title">{title ? title : 'no title'}</div>
        <div className="project_description">
          {shortDescription ? shortDescription : 'no description'}
        </div>
      </div>
      {isStatusVisible && (
        <div
          className="project_status"
          style={{ backgroundColor: checkColor() }}
        />
      )}
      {isPublished() && isStatusVisible && (
        <Button
          className="project_edit_button"
          size="small"
          color="primary"
          variant="contained"
          onClick={(e) => {
            setIsStatusVisible(false);
            onClickEditBtn(e, item);
          }}
        >
          <img src={EditIcon} alt="React Logo" />
          EDIT
        </Button>
      )}
    </div>
  );
}
