// Common
import { useDispatch, useSelector } from 'react-redux';

// Action
import { handleChangeProject } from '../../../actions/projects';

// Components
import { Typography, Box, Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { RadioButtons } from '../../common/radio';
import { Select } from '../../common/select';
import { TooltipComponent } from '../../common/tooltip/index';

// Styles
import '../index.scss';

// Data
import { countries, currencies, categories } from './data';
import { useParams } from 'react-router-dom';
const radioValues = [
  { value: 'individual', label: 'Individual' },
  { value: 'company', label: 'Company' },
];

export default function StepOne() {
  const { id } = useParams();
  const project = useSelector((state) => state.projects.selected);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    dispatch(handleChangeProject({ [event.target.name]: event.target.value }));
  };

  return id !== 'new' && project._id === null ? (
    'loading...'
  ) : (
    <div>
      <Typography variant="h2">Basic information</Typography>
      <Box mt="10px">
        <Typography variant="subtitle2">
          In this step you need to fill out information about profile
        </Typography>
      </Box>
      <Box mt="48px">
        <Typography variant="h4">
          Let's start with updating your profile with necessary information
        </Typography>
        <RadioButtons
          values={radioValues}
          name="type"
          onChange={handleChange}
          value={project.type}
        />
      </Box>
      <div className="underline" />
      <Box mt="32px">
        <div style={{ display: 'flex' }}>
          <Typography variant="h4">Where are you located? </Typography>
          <TooltipComponent text="If you're an individual this is your residential country, for company use your headquarters country" />
        </div>
      </Box>
      <Box mt="24px" mb="32px">
        <Autocomplete
          name="location"
          value={countries.find((item) => item.value === project.location)}
          onChange={(event, newValue) =>
            handleChange({
              target: {
                name: 'location',
                value: newValue ? newValue.value : '',
              },
            })
          }
          options={countries}
          getOptionSelected={(option, value) => option.value === value?.value}
          getOptionLabel={(option) => option.name}
          style={{ width: 366 }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select residence country"
              name="location"
              variant="outlined"
            />
          )}
        />
      </Box>
      <div className="underline" />
      <Box mt="32px">
        <div style={{ display: 'flex' }}>
          <Typography variant="h4">Select your currency</Typography>
          <TooltipComponent text="Select your local currency" />
        </div>
      </Box>
      <Box mt="24px" mb="32px">
        <Grid container spacing={5}>
          <Grid item>
            <Select
              value={project.currency}
              values={currencies}
              onChange={handleChange}
              name="currency"
              label="Select currency"
            />
          </Grid>
          <Grid item>
            <Select
              value={project.category}
              values={categories}
              onChange={handleChange}
              name="category"
              label="Select a category for your project"
            />
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
