import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components
import { Typography, Box, Grid, Button } from '@material-ui/core';
import { showFile } from '../../../services/user';
import ImageUploader from '../../common/imageUploader/index';
import DocUploader from '../../common/docsUploader/index';
import { Multiline } from '../../common/input/multiline';
import { Input } from '../../common/input/index';
import { RadioButtons } from '../../common/radio';

// Actions
import { handleChangeProject, updateProject } from '../../../actions/projects';

// Images
import MessageIcon from '../../../images/icons_message.svg';

// Styles
import '../index.scss';

const radioValues = [
  {
    value: 'automatically',
    label:
      'If my project is approved in review, then publish automatically to the Investor app. This will start the subscription',
  },
  {
    value: 'manually',
    label: 'Let me choose when to publish the project manually',
  },
];

export default function About() {
  const [videoUrl, setVideoUrl] = useState('');
  const project = useSelector((state) => state.projects.selected);
  const {
    logo,
    mainImage,
    additionalImages,
    video,
    documents,
    shortDescription,
    longDescription,
    prototypeLinks,
    publishType,
  } = useSelector((state) => state.projects.selected);
  const status = useSelector((state) => state.projectStatuses.summary);
  const { numbers, sharesNumber, price, budget } = useSelector(
    (state) => state.projects.selected.investmentNeeds,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (video) {
      showFile(video).then((res) => setVideoUrl(res.data));
    }
  }, [video]);

  const handleChange = (event) => {
    const publishAutomatically =
      'manually' === event.target.value ? false : true;

    dispatch(
      handleChangeProject({
        [event.target.name]: event.target.value,
        publishAutomatically: publishAutomatically,
      }),
    );
  };

  const changeStatus = (status) => {
    let _project = Object.assign({}, project);
    _project.status = status;
    dispatch(updateProject(_project, `/dashboard`));
  };

  const renderButtons = (status) => {
    if (status === 'Not Ready' || status === 'In review') {
      return (
        <div className="about_buttons_block">
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => {}}
            disabled={true}
          >
            Send to review
          </Button>
        </div>
      );
    }

    if (
      status === 'Done' ||
      status === 'Rejected' ||
      status === 'Ready to send'
    ) {
      return (
        <div className="about_buttons_block">
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => changeStatus('Waiting for Review')}
            disabled={!publishType}
          >
            Send to review
          </Button>
        </div>
      );
    }

    if (status === 'Approved') {
      return (
        <div className="about_buttons_block">
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => changeStatus('Published')}
          >
            Publish
          </Button>
        </div>
      );
    }

    if (status === 'Published') {
      return (
        <div className="about_buttons_block">
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => {}}
            disabled={true}
          >
            Published
          </Button>
        </div>
      );
    }
  };

  return (
    <>
      <div className="about_buttons_block_center">
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={() => {}}
          startIcon={<img src={MessageIcon} alt="message_icon" />}
        >
          Message
        </Button>
      </div>
      <Box mt="30px" mb="30px">
        <Typography variant="h4">60 second pitсh video</Typography>
        {videoUrl && (
          <div className="show_video_wrapper">
            <video width="710" controls>
              <source src={videoUrl} />
            </video>
          </div>
        )}
      </Box>

      <div className="underline" />

      <Box mt="30px" mb="30px">
        <Typography variant="h4">Upload images</Typography>
        <Box mt="30px">
          <Grid
            container
            wrap="nowrap"
            alignItems={'flex-start'}
            justify={'flex-start'}
            spacing={6}
            xl
          >
            <Grid item>
              <Typography variant="h4">Main image</Typography>
              <br />
              <ImageUploader name="mainImage" imageUrl={mainImage} />
            </Grid>
            <Grid item container md>
              <Grid item>
                <Typography variant="h4">Additional images</Typography>
                <br />
                <Grid container spacing={6}>
                  {additionalImages.map((image, index) => (
                    <Grid item key={index}>
                      <ImageUploader name="additionalImage" imageUrl={image} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <div className="underline" />

      <Box mt="30px" mb="30px">
        <Typography variant="h4">Upload logo</Typography>
        <Box mt="24px" mb="32px">
          <ImageUploader name="logo" imageUrl={logo} />
        </Box>
      </Box>

      <div className="underline" />

      <Box mt="30px" mb="30px">
        <Typography variant="h4">Additional documents</Typography>
        <Box mt="30px">
          <Grid container spacing={3} alignItems="center">
            {documents.map((item, index) => (
              <Grid item key={index}>
                <DocUploader name="document" docUrl={item} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      <div className="underline" />

      <Box maxWidth="920px" mt="30px" mb="30px" minHeight="300px">
        <Grid container spacing={5} alignItems="flex-start">
          <Grid item xs={6}>
            <Typography variant="h4">Short Description</Typography>
            <br />
            <br />
            <Multiline
              label={'Short Description'}
              name={'shortDescription'}
              type="text"
              value={shortDescription}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="h4">Long Description</Typography>
            <br />
            <br />
            <Multiline
              label={'Long Description'}
              name={'longDescription'}
              type="text"
              value={longDescription}
            />
          </Grid>
        </Grid>
      </Box>

      <div className="underline" />

      <Box mt="30px" mb="30px" maxWidth="800px">
        <Box mb="30px">
          <Typography variant="h4">Investments requirements</Typography>
        </Box>
        <Box>
          <Grid container spacing={7}>
            <Grid item xs={6}>
              <Typography variant="h4">
                How many investors have invested in your company?
              </Typography>
              <br />
              <br />
              <Input
                label="Select number of investors"
                name="numbers"
                type="text"
                value={numbers}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h4">
                How many shares are you willing to sell?
              </Typography>
              <br />
              <br />
              <Input
                label="Shares are you willing to sell"
                name="sharesNumber"
                type="text"
                value={+sharesNumber}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h4">Price per share?</Typography>
              <br />
              <br />
              <Input
                label="Example $3"
                name="price"
                type="text"
                value={+price}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h4">Investment needs</Typography>
              <br />
              <br />
              <Input
                label="Project budget"
                name="budget"
                type="text"
                value={+budget}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>

      <div className="underline" />

      <Box mt="30px" mb="30px">
        <Box mb="30px">
          <Typography variant="h4">External Links</Typography>
        </Box>
        <Box>
          <Grid spacing={6} container>
            <Grid item>
              <Typography variant="h4">Link title</Typography>
              <br />
              <br />
              <Input
                label={'Link title'}
                name={'title'}
                type={'text'}
                value={prototypeLinks.title}
              />
            </Grid>
            <Grid item>
              <Typography variant="h4">
                URL to External presentation or prototype
              </Typography>
              <br />
              <br />
              <Input
                label={'Your link'}
                name={'link'}
                type={'text'}
                value={prototypeLinks.link}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box mt="40px" mb="30px">
        <div className="automatically_publish_wraper">
          <RadioButtons
            values={radioValues}
            name="publishType"
            onChange={handleChange}
            value={publishType}
          />
        </div>
      </Box>
      {renderButtons(status)}
    </>
  );
}
