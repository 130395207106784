// Components
import { Tooltip } from '@material-ui/core';

// Images
import InfoIcon from '../../../images/info_icon.svg';

// Styles
import './index.scss';

export function TooltipComponent({ text, icon }) {
  return (
    <Tooltip arrow title={text} placement="top-start">
      {icon ? (
        <img className="white_tooltip" src={icon} alt="tooltip_icon" />
      ) : (
        <div className="tooltip">
          <img src={InfoIcon} alt="info_icon" />
        </div>
      )}
    </Tooltip>
  );
}
