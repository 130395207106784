// Common
import { useState } from 'react';
import { getAbbreviation } from '../profile/abbreviation';
import { makeStyles } from '@material-ui/core/styles';

// Componets
import {
  AppBar,
  Menu,
  MenuItem,
  ListItemIcon,
  IconButton,
} from '@material-ui/core';
import MoreIcon from '../../images/icons_more.svg';
import BlockIcon from '../../images/icons_block.svg';
import ReportIcon from '../../images/icons_report.svg';
import './index.scss';

const useStyles = makeStyles({
  button: {
    backgroundColor: '#f5f5f5',
    border: 'none',
    fontSize: '16px',
    lineHeight: 1.5,
    color: '#393939',
    fontFamily: 'SFProDisplay-Medium',
  },
  rootMenu: {
    '& .MuiPaper-root': {
      width: '201px',
      boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.07)',
      borderRadius: '8px',
      padding: '8px 0  ',
    },
  },
  menuItem: {
    height: '48px',
    fontSize: '14px',
    fontFamily: 'SFProDisplay-Medium',
    color: '#393939',
    lineHeight: 1.29,
    '& .MuiListItemIcon-root': {
      minWidth: '36px',
    },
  },
});

export default function ChatHeader({ contact }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="inerit"
      color="default"
      style={{
        padding: '0 24px',
        zIndex: 1201,
        flexDirection: 'row',
        alignItems: 'center',
        borderBottom: '1px solid #efefef',
        boxShadow: 'none',
        height: '72px',
        justifyContent: 'space-between',
      }}
    >
      <div className="avatar_and_name">
        {contact?.picture ? (
          <img className="header_avatar" src={contact.picture} alt="header" />
        ) : (
          <div className="header_avatar">
            {contact && getAbbreviation(contact?.name)}
          </div>
        )}
        <div>{contact?.name}</div>
      </div>

      <IconButton className={classes.button} onClick={handleClick}>
        <img className="more_image" src={MoreIcon} alt="more" />
      </IconButton>
      <Menu
        className={classes.rootMenu}
        elevation={0}
        getContentAnchorEl={null}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem className={classes.menuItem}>
          <ListItemIcon>
            <img src={BlockIcon} alt="profile_icon" />
          </ListItemIcon>
          Block user
        </MenuItem>
        <MenuItem className={classes.menuItem}>
          <ListItemIcon>
            <img src={ReportIcon} alt="profile_icon" />
          </ListItemIcon>
          Report user
        </MenuItem>
      </Menu>
    </AppBar>
  );
}
