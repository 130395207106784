import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getPreview } from '../../services/projects';
import { Box, Paper } from '@material-ui/core';
import LikesIcon from '../../images/like_icon.png';
import AppStoreIcon from '../../images/app_store_icon.png';
import GooglePlayIcon from '../../images/google_play_icon.png';

import './index.scss';

export default function Preview() {
  const [previewData, setPreviewData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  const getLikesTitle = () => {
    if (previewData && typeof previewData.likes === 'number') {
      if (previewData.likes === 0) {
        return 'Like';
      }

      if (previewData.likes > 0) {
        return 'Likes';
      }
    }
  };

  useEffect(() => {
    getPreview(id)
      .then((res) => {
        setPreviewData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });

    return () => setPreviewData(null);
  }, [id]);

  if (loading) {
    return <>Loading...</>;
  }

  if (!previewData) {
    return <>Ops! Something went wrong...</>;
  }

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Paper className="container" elevation={3}>
        <Box
          margin="20px"
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <img
            alt="main-img"
            className="container-main-img"
            src={previewData.mainImage}
          />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
            marginTop="10px"
          >
            <Box display="flex" alignItems="center">
              <img
                alt="likes-img"
                className="container-likes-img"
                src={LikesIcon}
              />
              <span className="container-likes">{previewData.likes}</span>
              <span className="container-likes-title">{getLikesTitle()}</span>
            </Box>
            <span className="container-owner">{previewData.owner}</span>
          </Box>
          <span className="container-title">{previewData.title}</span>
          <span className="container-shortDescription">
            {previewData.shortDescription}
          </span>
        </Box>
      </Paper>

      <Box className="links">
        <a href="https://apps.apple.com/ua/app/moneyflow-invest/id1582595973">
          <img alt="app-store" className="links-icons" src={AppStoreIcon} />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.pettersonapps.moneyflow.prod">
          <img alt="google-play" className="links-icons" src={GooglePlayIcon} />
        </a>
      </Box>
    </Box>
  );
}
