// Common
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';

// Components
import { Stepper, Step, StepLabel, Grid, Button, Box } from '@material-ui/core';
import StepOne from './stepOne';
import StepTwo from './stepTwo';

// Actions
import { createProject, updateProject } from '../../../actions/projects';

// Styles
import '../index.scss';

export default function Basics() {
  const [activeStep, setActiveStep] = useState(0);
  const project = useSelector((state) => state.projects.selected);
  const errors = useSelector((state) => state.errors);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();

  useEffect(() => {
    if (location.hash === '#step2') {
      setActiveStep(1);
    }
    if (location.hash === '#step1') {
      setActiveStep(0);
    }
  }, [location.hash]);

  const checkActiveNextButton = () => {
    let active = true;
    const { type, category, currency } = project;
    if (type && category && currency && activeStep === 0) {
      active = false;
    }
    if (activeStep === 1 && !Object.keys(errors).length) {
      active = false;
    }
    return active;
  };

  const onClickNext = () => {
    if (activeStep === 0) {
      history.push(`/project/basics/${id}#step2`);
    }
    if (activeStep === 1) {
      if (id !== 'new') {
        dispatch(updateProject(project, `/project/description/${project._id}`));
      } else {
        dispatch(createProject(project, `/project/description/`));
      }
    }
  };

  return (
    <div className="project_form_container">
      {activeStep === 0 ? <StepOne /> : <StepTwo />}
      <div className="next_back_buttons_container">
        <Grid
          container
          alignItems="center"
          justify="space-between"
          wrap="nowrap"
        >
          <Grid item>
            <Button
              variant="outlined"
              size="small"
              color="primary"
              disabled={activeStep === 0}
              onClick={() => history.push(`/project/basics/${id}#step1`)}
            >
              Back
            </Button>
          </Grid>
          <Grid item>
            <Box width="160px">
              <Stepper activeStep={activeStep}>
                <Step>
                  <StepLabel>1</StepLabel>
                </Step>
                <Step>
                  <StepLabel>2</StepLabel>
                </Step>
              </Stepper>
            </Box>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="small"
              color="primary"
              disabled={checkActiveNextButton()}
              onClick={() => onClickNext()}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
