import {
  getProjects,
  getProject,
  postProject,
  putProject,
  postVideo,
  deleteVideo,
  postMainImage,
  postLogo,
  postAdditionalImage,
  deleteImage,
  postDocuments,
  reviewHistory,
  getInvestors,
  postTeamMemberAvatar,
} from '../services/projects';

import history from '../history';

import { SET_ERRORS, CLEAR_ERRORS } from './errors';

export const SET_PROJECTS = 'PROJECT/SET_PROJECTS';
export const SET_PROJECT = 'PROJECT/SET_PROJECT';
export const CLEAR_PROJECT = 'PROJECT/CLEAR_PROJECT';
export const HANDLE_CHANGE_SELECTED = 'PROJECT/HANDLE_CHANGE_SELECTED';
export const HANDLE_CHANGE_OWNER_COMPANY =
  'PROJECT/HANDLE_CHANGE_OWNER_COMPANY';
export const HANDLE_CHANGE_OWNER_TEAM = 'HANDLE_CHANGE_OWNER_TEAM';
export const HANDLE_CHANGE_BUSINESS_MODEL = 'HANDLE_CHANGE_BUSINESS_MODEL';
export const SET_UPLOADING_FILE = 'PROJECT/SET_UPLOADING_FILE';
export const SET_TEAM_MEMBER_AVATAR = 'SET_TEAM_MEMBER_AVATAR';
export const CHANGE_PROTOTYPE_LINK = 'PROJECT/CHANGE_PROTOTYPE_LINK';
export const ADD_NEW_PROTOTYPE_LINK = 'PROJECT/ADD_NEW_PROTOTYPE_LINK';
export const HANDLE_CHANGE_INVESTME_NNEEDS =
  'PROJECT/HANDLE_CHANGE_INVESTME_NNEEDS';
export const SET_STATUSES = 'PROJECT_STATUSES/SET_STATUSES';

export const handleChnageProjectStatuses = (value) => {
  return {
    type: SET_STATUSES,
    statuses: value,
  };
};

export const handleChangeProject = (value) => {
  return {
    type: HANDLE_CHANGE_SELECTED,
    selected: { ...value },
  };
};

export const handleChangeOwnerCompany = (value) => {
  return {
    type: HANDLE_CHANGE_OWNER_COMPANY,
    selected: { ...value },
  };
};

export const handleChangeOwnerTeam = (value) => {
  return {
    type: HANDLE_CHANGE_OWNER_TEAM,
    selected: value,
  };
};

export const handleChangeBusinessModel = (value) => {
  return {
    type: HANDLE_CHANGE_BUSINESS_MODEL,
    selected: value,
  };
};

export const handleClearProject = () => {
  return {
    type: CLEAR_PROJECT,
  };
};

export const projectsList = () => {
  return (dispatch) => {
    getProjects()
      .then((res) => {
        dispatch({ type: SET_PROJECTS, projects: res.data.rows || [] });
      })
      .catch((error) => {
        // dispatch({ type: SET_ERRORS, errors: {} })
        dispatch({ type: SET_PROJECTS, projects: [] });
      });
  };
};

export const createProject = (project, nextLink) => {
  return (dispatch) => {
    postProject(project)
      .then((res) => {
        dispatch({ type: SET_PROJECT, selected: res.data });
        if (nextLink) {
          history.push(nextLink + res.data._id);
        }
      })
      .catch((error) => {
        // dispatch({ type: SET_ERRORS, errors: {} })
      });
  };
};

export const updateProject = (project, nextLink) => {
  return (dispatch) => {
    project.status = project.status === 'Rejected' ? null : project.status;
    putProject(project)
      .then((res) => {
        dispatch({ type: SET_PROJECT, selected: res.data });
        dispatch({ type: CLEAR_ERRORS });
        if (nextLink) {
          history.push(nextLink);
        }
      })
      .catch((error) => {
        const isZipError = error.message.search(/zip/);
        if (isZipError !== -1)
          dispatch({ type: SET_ERRORS, errors: { zip: 'Is not a Zip Code!' } });
      });
  };
};

export const getOneProject = (project) => {
  return (dispatch) => {
    getProject(project)
      .then((res) => {
        const {
          data: {
            businessModel: { revenueStreams, ...businessModel },
          },
        } = res;
        for (const field in businessModel) {
          if (businessModel[field].length === 0) {
            businessModel[field].push('');
          }
        }
        for (const obj_field in revenueStreams) {
          if (revenueStreams[obj_field].length === 0) {
            revenueStreams[obj_field].push('');
          }
        }
        dispatch({ type: SET_PROJECT, selected: res.data });
      })
      .catch((error) => {
        // dispatch({ type: SET_ERRORS, errors: {} })
      });
  };
};

export const uploadVideo = (video, projectId) => {
  return (dispatch) => {
    dispatch({ type: SET_UPLOADING_FILE, uploadingFile: true });
    postVideo(video, projectId)
      .then((res) => {
        dispatch({ type: SET_PROJECT, selected: res.data });
        dispatch({ type: SET_UPLOADING_FILE, uploadingFile: false });
      })
      .catch((error) => {
        // dispatch({ type: SET_ERRORS, errors: {} })
        dispatch({ type: SET_UPLOADING_FILE, uploadingFile: false });
      });
  };
};

export const removeVideo = (projectId, path) => {
  return (dispatch) => {
    deleteVideo(projectId, path)
      .then((res) => {
        dispatch({ type: SET_PROJECT, selected: res.data });
      })
      .catch((error) => {
        // dispatch({ type: SET_ERRORS, errors: {} })
      });
  };
};

export const removeImage = (projectId, path, imageType) => {
  return (dispatch) => {
    deleteImage(projectId, path, imageType)
      .then((res) => {
        dispatch({ type: SET_PROJECT, selected: res.data });
      })
      .catch((error) => {
        // dispatch({ type: SET_ERRORS, errors: {} })
      });
  };
};

export const uploadMainImage = (image, projectId) => {
  return (dispatch) => {
    dispatch({ type: SET_UPLOADING_FILE, uploadingFile: true });
    postMainImage(image, projectId)
      .then((res) => {
        dispatch({ type: SET_PROJECT, selected: res.data });
        dispatch({ type: SET_UPLOADING_FILE, uploadingFile: false });
      })
      .catch((error) => {
        // dispatch({ type: SET_ERRORS, errors: {} })
        dispatch({ type: SET_UPLOADING_FILE, uploadingFile: false });
      });
  };
};

export const uploadLogo = (image, projectId) => {
  return (dispatch) => {
    dispatch({ type: SET_UPLOADING_FILE, uploadingFile: true });
    postLogo(image, projectId)
      .then((res) => {
        dispatch({ type: SET_PROJECT, selected: res.data });
        dispatch({ type: SET_UPLOADING_FILE, uploadingFile: false });
      })
      .catch((error) => {
        // dispatch({ type: SET_ERRORS, errors: {} })
        dispatch({ type: SET_UPLOADING_FILE, uploadingFile: false });
      });
  };
};

export const uploadTeamMemberAvatar = (image, index) => {
  return (dispatch) => {
    dispatch({ type: SET_UPLOADING_FILE, uploadingFile: true });
    postTeamMemberAvatar(image)
      .then((res) => {
        dispatch({
          type: SET_TEAM_MEMBER_AVATAR,
          selected: { url: res.data.fileName, index: index },
        });
        dispatch({ type: SET_UPLOADING_FILE, uploadingFile: false });
        dispatch({ type: SET_TEAM_MEMBER_AVATAR, selected: null });
      })
      .catch((error) => {
        // dispatch({ type: SET_ERRORS, errors: {} })
        dispatch({ type: SET_UPLOADING_FILE, uploadingFile: false });
      });
  };
};

export const uploadAdditionalImage = (image, projectId) => {
  return (dispatch) => {
    dispatch({ type: SET_UPLOADING_FILE, uploadingFile: true });
    postAdditionalImage(image, projectId)
      .then((res) => {
        dispatch({ type: SET_PROJECT, selected: res.data });
        dispatch({ type: SET_UPLOADING_FILE, uploadingFile: false });
      })
      .catch((error) => {
        // dispatch({ type: SET_ERRORS, errors: {} })
        dispatch({ type: SET_UPLOADING_FILE, uploadingFile: false });
      });
  };
};

export const changePrototypeLink = (prototypeLink) => {
  return {
    type: CHANGE_PROTOTYPE_LINK,
    prototypeLink,
  };
};

// export const addNewPrototypeLink = () => {
//   return {
//     type: ADD_NEW_PROTOTYPE_LINK,
//   };
// };

export const uploadDocuments = (files, projectId) => {
  return (dispatch) => {
    dispatch({ type: SET_UPLOADING_FILE, uploadingFile: true });
    postDocuments(files, projectId)
      .then((res) => {
        dispatch({ type: SET_PROJECT, selected: res.data });
        dispatch({ type: SET_UPLOADING_FILE, uploadingFile: false });
      })
      .catch((error) => {
        // dispatch({ type: SET_ERRORS, errors: {} })
        dispatch({ type: SET_UPLOADING_FILE, uploadingFile: false });
      });
  };
};

export const handleChangeInvestmenNeeds = (value) => {
  return {
    type: HANDLE_CHANGE_INVESTME_NNEEDS,
    selected: { ...value },
  };
};

export const historyList = (projectId) => {
  return (dispatch) => {
    reviewHistory(projectId).then((res) => {
      dispatch({
        type: HANDLE_CHANGE_SELECTED,
        selected: { reviewHistory: res.data.reverse() } || [],
      });
    });
  };
};

export const investorsList = (projectId) => {
  return (dispatch) => {
    getInvestors(projectId).then((res) => {
      dispatch({
        type: HANDLE_CHANGE_SELECTED,
        selected: { investors: res.data.rows } || [],
      });
    });
  };
};
