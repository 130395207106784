const initialState = {
  text: {},
};

const translationReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default translationReducer;
