// Common
import { useSelector } from 'react-redux';

// Components
import { Box, ThemeProvider, Typography } from '@material-ui/core';

// Styles
import { details } from './customStyles';

export default function ContactsCard() {
  const user = useSelector((state) => state.user.data);

  return (
    <Box
      width="383px"
      height="334px"
      boxShadow="0 0 15px 0 rgba(0, 0, 0, 0.07)"
      marginLeft="32px"
    >
      <Box m="32px 32px 38px 32px">
        <ThemeProvider theme={details}>
          <Typography variant="h1">Contacts</Typography>
          <Typography variant="h2">City</Typography>
          <Typography variant="h3" color="secondary">
            {user.city}
          </Typography>
          <Typography variant="h2">Address</Typography>
          <Typography variant="h3" color="secondary">
            {user.address}
          </Typography>
          <Typography variant="h2">Telephone number</Typography>
          <Typography variant="h3" color="secondary">
            {user.phone}
          </Typography>
        </ThemeProvider>
      </Box>
    </Box>
  );
}
