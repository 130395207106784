// Common
import { useState } from 'react';

// Components
import { Typography, Box, Tabs, Tab } from '@material-ui/core';
import About from './about';
import ReviewHistory from './reviewHistory';
import InvestorsList from './investorsList';

// Styles
import '../index.scss';

export default function Summary() {
  const [openTab, setOpenTab] = useState(0);

  const handleChangeTabs = (event, newValue) => {
    setOpenTab(newValue);
  };
  return (
    <div className="project_form_container">
      <Typography variant="h2">Summary</Typography>
      <Box mt="10px">
        <Typography variant="subtitle2">
          This is the summary of your project. Before you can publish the
          project in the MoneyFlow app, our professional team need to do a
          review of your project to make sure that the quality of your investor
          deck follows our guidelines and requirements. When all required fields
          are filled out, then the next step is to send the presentation to
          review. The review time will take from 1-3 days.
        </Typography>
      </Box>
      <Box mt="30px">
        <Tabs
          value={openTab}
          onChange={handleChangeTabs}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab value={0} label="About" />
          <Tab value={1} label="List of investors" />
          <Tab value={2} label="Review history" />
        </Tabs>
        <div className="underline_gray" />
      </Box>
      {openTab === 0 && <About />}
      {openTab === 2 && <ReviewHistory />}
      {openTab === 1 && <InvestorsList />}
    </div>
  );
}
