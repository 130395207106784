import {
  SET_CHATS,
  SET_MESSAGES,
  ADD_NEW_MESSAGE,
  SET_SELECTED_CHAT,
  CLEAR,
} from '../actions/messages';

const initialState = {
  chats: [],
  messages: [],
  selectedChat: {
    _id: null,
  },
};

const messages = (state = initialState, action) => {
  switch (action.type) {
    case SET_CHATS:
      return { ...state, chats: action.chats };

    case SET_MESSAGES:
      return { ...state, messages: action.messages };

    case ADD_NEW_MESSAGE:
      let newMessages = state.messages.slice();
      newMessages.unshift(action.message);
      return { ...state, messages: newMessages };

    case SET_SELECTED_CHAT:
      console.log();
      return { ...state, selectedChat: action.chat };

    case CLEAR:
      return { ...state, messages: [] };

    default:
      return state;
  }
};

export default messages;
