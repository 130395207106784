import {
  SET_PROJECTS,
  SET_PROJECT,
  HANDLE_CHANGE_SELECTED,
  HANDLE_CHANGE_OWNER_COMPANY,
  SET_UPLOADING_FILE,
  CHANGE_PROTOTYPE_LINK,
  ADD_NEW_PROTOTYPE_LINK,
  HANDLE_CHANGE_INVESTME_NNEEDS,
  CLEAR_PROJECT,
  HANDLE_CHANGE_OWNER_TEAM,
  HANDLE_CHANGE_BUSINESS_MODEL,
  SET_TEAM_MEMBER_AVATAR,
} from '../actions/projects';

const initialState = {
  index: null,
  selected: {
    _id: null,
    status: '',
    type: '',
    title: '',
    isUnderNDA: false,
    shortDescription: '',
    longDescription: '',
    location: '',
    currency: '',
    category: '',
    ownerCompany: {
      name: '',
      address: '',
      zip: '',
      city: '',
      email: '',
      mobile: '',
      vatNumber: '',
      countryCode: '',
    },
    video: '',
    mainImage: '',
    logo: '',
    team: [],
    additionalImages: [],
    prototypeLinks: {
      title: '',
      link: '',
    },
    businessModel: {
      customerSegment: [''],
      valuePropositions: [''],
      channels: [''],
      customerRelationships: [''],
      revenueStreams: {
        types: [''],
        fixedPricing: [''],
        dynamicPricing: [''],
      },
      keyResources: [''],
      keyActivities: [''],
      keyPartners: [''],
      costStructure: [''],
    },
    documents: [],
    investmentNeeds: {
      number: null,
      sharesNumber: null,
      price: null,
      budget: null,
    },
    publishType: 'manually',
    publishAutomatically: false,
    stages: '',
    investmentTypes: [],
    reviewHistory: [],
    investors: [],
  },
  uploadingFile: false,
  uploadingFileUrl: null,
};

const projectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROJECTS:
      return {
        ...state,
        index: action.projects,
      };
    case SET_TEAM_MEMBER_AVATAR:
      return {
        ...state,
        uploadingFileUrl: action.selected,
      };
    case SET_PROJECT:
      return {
        ...state,
        selected: { ...state.selected, ...action.selected },
      };

    case CLEAR_PROJECT:
      return {
        ...state,
        selected: initialState.selected,
      };

    case HANDLE_CHANGE_SELECTED:
      return {
        ...state,
        selected: { ...state.selected, ...action.selected },
      };

    case HANDLE_CHANGE_OWNER_COMPANY:
      return {
        ...state,
        selected: {
          ...state.selected,
          ownerCompany: { ...state.selected.ownerCompany, ...action.selected },
        },
      };

    case HANDLE_CHANGE_OWNER_TEAM:
      return {
        ...state,
        selected: {
          ...state.selected,
          team: action.selected,
        },
      };

    case HANDLE_CHANGE_BUSINESS_MODEL:
      return {
        ...state,
        selected: {
          ...state.selected,
          businessModel: action.selected,
        },
      };

    case HANDLE_CHANGE_INVESTME_NNEEDS:
      return {
        ...state,
        selected: {
          ...state.selected,
          investmentNeeds: {
            ...state.selected.investmentNeeds,
            ...action.selected,
          },
        },
      };

    case SET_UPLOADING_FILE:
      return {
        ...state,
        uploadingFile: action.uploadingFile,
      };

    case CHANGE_PROTOTYPE_LINK:
      return {
        ...state,
        selected: { ...state.selected, prototypeLinks: action.prototypeLink },
      };

    case ADD_NEW_PROTOTYPE_LINK:
      let newArray = state.selected.prototypeLinks.slice();
      newArray.push({ _id: null, title: '', link: '' });
      return {
        ...state,
        selected: { ...state.selected, prototypeLinks: newArray },
      };

    default:
      return state;
  }
};

export default projectsReducer;
