// Cpmmon
import { useState, useEffect } from 'react';
import moment from 'moment';

// Images
import TimeIcon from '../../../images/icons_time.svg';
import CalendarIcon from '../../../images/icons_calendar.svg';
import ApprovedIcon from '../../../images/icons_approved_small.svg';
import ErrorIcon from '../../../images/icons_error_small.svg';
import InReviewIcon from '../../../images/icons_in_review_white_small.svg';
import RejectIcon from '../../../images/icons_reject_small.svg';

import '../index.scss';

export default function ReviewHistoryItem({ item }) {
  const [time, setTime] = useState('');
  const [date, setDate] = useState('');
  const [image, setImage] = useState('');
  const [color, setColor] = useState('');

  useEffect(() => {
    setTime(moment(item.createdAt).format('h:mm'));
    setDate(moment(item.createdAt).format('L').replaceAll('/', '.'));
    setImage(checkIcon(item.status));
  }, [setTime, item]);

  const checkIcon = (status) => {
    switch (status) {
      case 'In review':
        setColor('#fdc22a');
        return InReviewIcon;
      case 'Approved':
        setColor('#5cc87a');
        return ApprovedIcon;
      case 'Waiting for Review':
        setColor('#dddddd');
        return ErrorIcon;
      case 'Rejected':
        setColor('#f15642');
        return RejectIcon;
      case 'Published':
        setColor('#5cc87a');
        return ApprovedIcon;
      default:
        setColor('#dddddd');
        return ErrorIcon;
    }
  };

  return (
    <div className="history_item" id={item._id}>
      <div className="history-status">{item.status}</div>
      <div className="history-message">{item.message}</div>
      <div className="date_time_container">
        <div>
          <img className="time_icon" src={TimeIcon} alt="time_icon" />
          {time}
        </div>
        <div>
          {date}
          <img className="calendar_icon" src={CalendarIcon} alt="time_icon" />
        </div>
      </div>
      <img className="color_icon" src={image} alt="color_icon" />
      <div className="color_cyrcle" style={{ backgroundColor: color }} />
    </div>
  );
}
