export const getAbbreviation = (name) => {
  const abbreviation = name.split(' ');
  if (abbreviation.length > 1) {
    return (
      abbreviation[0].slice(0, 1).toUpperCase() +
      ' ' +
      abbreviation[1].slice(0, 1).toUpperCase()
    );
  } else {
    return abbreviation[0].slice(0, 1).toUpperCase();
  }
};
