// Common
import { useState, useEffect } from 'react';
import { showFile } from '../../../services/user';

//Components
import { CircularProgress, Typography } from '@material-ui/core';

// Images
import UploadIcons from '../../../images/icons_upload_dark.svg';
import CloseIcon from '../../../images/icons_close_white.svg';
import ErrorIcon from '../../../images/icons_error.svg';

// Styles
import './index.scss';

// Constants
const ACCEPTED_FORMATS = ['mov', 'x-msvideo', 'mpeg', 'mp4', 'ogg'];

export default function VideoUploader({
  name,
  videoUrl,
  onChange,
  onDestroy,
  videoUploading,
}) {
  const [video, setVideo] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (videoUrl) {
      showFile(videoUrl).then((res) => setVideo(res.data));
    }
  }, [videoUrl]);

  const destroyVideo = () => {
    onDestroy();
    setVideo('');
  };

  const validateVideo = (event) => {
    const file = event.target.files[0];
    if (!event?.target?.files[0].type.match('video.*')) {
      event.target.value = '';
      setError("It's not video file!");
      return;
    }

    const format = file?.name?.split('.')?.reverse()[0];

    if (
      !format ||
      (format && !ACCEPTED_FORMATS.find((el) => format.toLowerCase() === el))
    ) {
      setError('Supported video formats: x-msvideo, mpeg, mp4, mov, ogg.');
      return;
    }

    const maxVideoSize = 150 * 1024 * 1024;
    if (event.target.files[0].size > maxVideoSize) {
      setError('Video should be maximum 150MB');
      return;
    }

    let video = document.createElement('video');
    video.preload = 'metadata';
    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      if (video.duration > 60) {
        setError('Video should be 60 seconds long.');
        return;
      }
      setError('');
      onChange(event);
    };
    video.src = URL.createObjectURL(file);
  };

  const preventDefault = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const dt = event.dataTransfer;
    const e = { target: { files: dt.files } };
    validateVideo(e);
    event.stopPropagation();
  };

  return (
    <div>
      {video ? (
        <div className="show_video_wrapper">
          <video width="356" controls src={video}>
            {/* <source  type="video/mp4"/> */}
          </video>
          <div className="delete_video_button" onClick={destroyVideo}>
            <img src={CloseIcon} alt="close_icon" />
          </div>
        </div>
      ) : (
        <div
          className="upload_video_wrapper"
          onDrop={handleDrop}
          onDragEnter={preventDefault}
          onDragOver={preventDefault}
          onDragLeave={preventDefault}
        >
          {videoUploading ? (
            <CircularProgress />
          ) : (
            <img src={UploadIcons} alt="upload_icons_dark" />
          )}

          <div className="upload_video-large_text">Drag and drop an video</div>
          <div className="upload_video-small_text">
            or{' '}
            <span
              className="upload_button"
              onClick={() => document.getElementById('upload_input').click()}
            >
              browse
            </span>{' '}
            to choose a file
          </div>
          <input
            id="upload_input"
            type="file"
            accept=".mov,.x-msvideo,.mpeg,.mp4,.ogg"
            name={name}
            onChange={validateVideo}
          />
        </div>
      )}
      {error && (
        <div className="error_text">
          <img src={ErrorIcon} alt="error_icon" />
          <Typography color="error">{error}</Typography>
        </div>
      )}
    </div>
  );
}
