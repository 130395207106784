import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const register = (data) => {
  if (data?.phone && data?.countryCode) {
    data.phone = data.countryCode + data.phone;
  }
  return axios.post(`${API_URL}/users`, data);
};

export const login = (data) => {
  return axios.post(
    `${API_URL}/auth/owner`,
    {},
    { auth: { username: data.email, password: data.password } },
  );
};

export const checkUser = (session_token) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${session_token}`;
  return axios.get(`${API_URL}/users/me`);
};

export const forgotPassword = (data) => {
  return axios.post(`${API_URL}/password-reset`, {
    email: data,
  });
};

export const resetPassword = (data) => {
  return axios.put(`${API_URL}/password-reset/${data.token}`, {
    password: data.new_password,
  });
};

export const editProfile = (data, id, picture) => {
  const company = {
    details: data.desc,
    name: data.company_name,
  };
  const links = {
    facebook: data.facebook,
    twitter: data.twitter,
    youtube: data.youtube,
    linkedin: data.linkedin,
  };
  return axios.put(
    `${API_URL}/users/${id}`,
    { ...data, links: links, company: company, picture: picture },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('session_token')}`,
      },
    },
  );
};

export const UploadAvatarImg = (data) => {
  let formData = new FormData();
  formData.append('image', data);
  return axios.post(
    `${process.env.REACT_APP_API_URL}/files/userpicture`,
    formData,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('session_token')}`,
      },
    },
  );
};

export const DeleteCurrentAvatar = (id, path) => {
  return axios.delete(
    `${process.env.REACT_APP_API_URL}/files/${id}/picture`,
    { data: { path } },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('session_token')}`,
      },
    },
  );
};

export const showFile = (url) => {
  return axios.get(`${process.env.REACT_APP_API_URL}/files?url=${url}`);
};

export const verifyUser = (code) => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/users/verify/${code}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('session_token')}`,
      },
    },
  );
};

export const sendUserCode = () => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/users/send-code`,
    {},
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('session_token')}`,
      },
    },
  );
};

export const verifyResetPass = (code, token) => {
  return axios.put(
    `${process.env.REACT_APP_API_URL}/password-reset/verify/${token}`,
    {
      code: code,
    },
  );
};
