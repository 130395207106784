// Common
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

// Components
import { Typography, Grid, Button, Box } from '@material-ui/core';

// Styles
import './index.scss';

export default function EmptyDashboard() {
  const history = useHistory();
  const userName = useSelector((state) => state.user.data.name);

  return (
    <Box mt="210px">
      <Grid
        container
        alignContent="center"
        alignItems="center"
        direction="column"
        justify="center"
        spacing={3}
      >
        <Grid item>
          <Typography variant="h1">{'Hello! ' + userName}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6">
            You don't have any projects yet. To create a project please click
            the create button
          </Typography>
        </Grid>
        <Grid item>
          <Box mt="40px">
            <Button
              variant="contained"
              size="medium"
              color="primary"
              onClick={() => history.push('/project/basics/new')}
            >
              Create a project
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
