// Common
import { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  IconButton,
} from '@material-ui/core';
import { Multiline } from '../../common/input/multiline';
import { makeStyles } from '@material-ui/core/styles';
import IconClose from '../../../images/icons_close_white.svg';

const useStyles = makeStyles({
  closeIcon: {
    position: 'absolute',
    top: '-50px',
    right: '-50px',
    backgroundColor: '#c7c7c7',
  },
});

export default function MessageDialog({ handleClose, open, send }) {
  const [message, setMessage] = useState('');
  const classes = useStyles();

  const handleChangeMessage = (event) => {
    setMessage(event.target.value);
  };

  const sendMessage = () => {
    send({ content: message, contentType: 'text' });
    handleClose();
    setMessage('');
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <IconButton
        aria-label="close"
        className={classes.closeIcon}
        onClick={() => handleClose()}
      >
        <img src={IconClose} alt="close_icon" />
      </IconButton>
      <DialogTitle>
        <Typography align="center" variant="body1">
          Here you can send a message to your Investor. You can continue the
          conversation in the "Messages" section.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Multiline
          name={'message'}
          type="text"
          value={message}
          onChange={handleChangeMessage}
          validation={false}
        />
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          color="primary"
          variant="outlined"
          onClick={() => handleClose()}
        >
          Cancel
        </Button>
        <div style={{ width: '20px' }} />
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={() => sendMessage()}
          disabled={!message}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
}
