// Common
import { Typography, Box, Grid } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import HelperIcon from '../../images/icons_Help.svg';
import { ReactComponent as AddIcon } from '../../images/icons_Plus.svg';
import { ReactComponent as DeleteBisModel } from '../../images/DeleteBisModel.svg';

import './index.scss';

import { AddBMButton, SaveBMButton } from './bisinessModelStyles';
import {
  handleChangeBusinessModel,
  updateProject,
} from '../../actions/projects';

import { TooltipComponent } from '../common/tooltip/index';

const tooltipsText = {
  revenueStreams:
    'For what value are our costomers really willing to pay? \nFor what do they currently pay? \nHow are they currently paying? \nHow would they prefer to pay? \nHow much does each Revenue Stream contribute to overall revenues?',
  customerSegment:
    'For whom are we creating value? \nWho are our most important customers?',
  valuePropositions:
    "What value do we deliver to the customers? \nWhich one of our customr's problems are we helping to solve? \nWhat bundies of products and services are we offering to each Customer Segment? \nWhich customer needs are we satisfying?",
  channels:
    'Through which Channels do our Customer Segments want to be reached? \nHow are we reaching them now? \nHow are our Channels integrated? \nWhich ones work best? \nWhich ones are most cost-efficient? \nHow are we integrating them with customer routines?',
  customerRelationships:
    'What type of relationship does each of our \nCustomer Segments expect us to establish \nand maintain with them? \nWhich ones heve we established? \nHow are they integrated with the rest of our \nbusiness model? \nHow costly are they?',
  keyResources:
    'What Key Resources do our Value Propositions require? \nOur Distribution Channels? Customer Relationships? \nRevenue Streams?',
  keyActivities:
    'What key Activities do our Value Propositions require? \nOur Distribution Channels? \nCustomer Relationships? Revenue Streams?',
  keyPartners:
    'Who are our Key Partners? \nWho are our key suppliers? \nWhich Key Resources are we acquairing from partners? \nWhich Key Activities do partners perform?',
  costStructure:
    'What are the most important cost inherent in our business model? \nWhich Key Resources are most expensive? \nWhich Key Activities are most expensive?',
};

const businessModelNames = [
  { title: 'Customer segment', key: 'customerSegment' },
  { title: 'Value propositions', key: 'valuePropositions' },
  { title: 'Channels', key: 'channels' },
  { title: 'Customer relationship', key: 'customerRelationships' },
  { title: 'Revenue streams', key: 'revenueStreams' },
  { title: 'Key recources', key: 'keyResources' },
  { title: 'Key activities', key: 'keyActivities' },
  { title: 'Partners', key: 'keyPartners' },
  { title: 'Cost structure', key: 'costStructure' },
];

export default function BisinessModel() {
  const project = useSelector((state) => state.projects.selected);
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(true);
  // const [showSaveAlert, setSaveAlert] = useState(false);

  const getTitle = (title) => {
    return title.split(/(?=[A-Z])/).length === 1
      ? title.charAt(0).toUpperCase() + title.slice(1)
      : title
          .split(/(?=[A-Z])/)[0]
          .charAt(0)
          .toUpperCase()
          .split(/(?=[A-Z])/) +
          title
            .slice(1)
            .split(/(?=[A-Z])/)
            .join(' ')
            .toLowerCase();
  };

  const updaterTime = (date) => {
    const dat = new Date(date);
    return (
      dat.getHours() +
      ':' +
      (dat.getMinutes() < 10 ? `0${dat.getMinutes()}` : dat.getMinutes())
    );
  };

  const addFields = (el, value) => {
    if (el === 'types' || el === 'fixedPricing' || el === 'dynamicPricing') {
      dispatch(
        handleChangeBusinessModel({
          ...project.businessModel,
          revenueStreams: { ...value, [el]: [...value[el], ''] },
        }),
      );
    } else {
      dispatch(
        handleChangeBusinessModel({
          ...project.businessModel,
          [el]: [...value, ''],
        }),
      );
    }
  };

  const changeValues = (value, id, field) => {
    if (
      field === 'types' ||
      field === 'fixedPricing' ||
      field === 'dynamicPricing'
    ) {
      dispatch(
        handleChangeBusinessModel({
          ...project.businessModel,
          revenueStreams: {
            ...project.businessModel.revenueStreams,
            [field]: project.businessModel.revenueStreams[field].map(
              (i, index) => (index === id ? value : i),
            ),
          },
        }),
      );
    } else {
      dispatch(
        handleChangeBusinessModel({
          ...project.businessModel,
          [field]: project.businessModel[field].map((i, index) =>
            index === id ? value : i,
          ),
        }),
      );
    }
  };

  const RemoveFields = (id, field) => {
    if (
      field === 'types' ||
      field === 'fixedPricing' ||
      field === 'dynamicPricing'
    ) {
      dispatch(
        handleChangeBusinessModel({
          ...project.businessModel,
          revenueStreams: {
            ...project.businessModel.revenueStreams,
            [field]: project.businessModel.revenueStreams[field].filter(
              (element, index) => index !== id,
            ),
          },
        }),
      );
    } else {
      dispatch(
        handleChangeBusinessModel({
          ...project.businessModel,
          [field]: project.businessModel[field].filter(
            (element, index) => index !== id,
          ),
        }),
      );
    }
  };

  useEffect(() => {
    const {
      businessModel: { revenueStreams, ...businessModel },
    } = project;
    const arr = [];
    for (const field in businessModel) {
      businessModel[field].map((el) => arr.push(el));
    }
    for (const obj_field in revenueStreams) {
      revenueStreams[obj_field].map((el) => arr.push(el));
    }
    arr.every((el) => el.length > 0) ? setDisabled(false) : setDisabled(true);
  }, [project]);

  useEffect(() => {
    const {
      businessModel: { revenueStreams, ...businessModel },
    } = project;
    for (const field in businessModel) {
      if (businessModel[field].length === 0) {
        businessModel[field].push('');
      }
    }
    for (const obj_field in revenueStreams) {
      if (revenueStreams[obj_field].length === 0) {
        revenueStreams[obj_field].push('');
      }
    }
  }, [project]);

  const SendChenges = useCallback(() => {
    dispatch(updateProject(project, `/project/summary/${project._id}`));
    // setSaveAlert(true)
    // setTimeout(() => {
    //   setSaveAlert(false)
    // }, 3000)
  }, [dispatch, project]);

  return (
    <Box mt="82px" ml="240px" position="relative">
      <Box
        width="20%"
        height="40px"
        bgcolor="#e1f0fa"
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="fixed"
        top="80px"
        right="0px"
        zIndex="1000"
        style={{
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px',
        }}
      >
        {/* <div className={showSaveAlert ? 'saved_alert visible' : 'saved_alert'}>
          Saved
        </div> */}
        <Typography variant="h4">
          {project?.updatedAt
            ? `Last updated:  ${updaterTime(project.updatedAt)}`
            : 'No updated'}
        </Typography>
      </Box>
      <Box padding="48px 32px 100px">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h1">Business model</Typography>
        </Box>
        <Box mt="30px">
          {businessModelNames.map((nameValue, index) => (
            <div key={index}>
              <Box
                display="flex"
                alignItems="center"
                marginBottom="30px"
                mt="30px"
              >
                <Typography variant="h2">{nameValue.title}</Typography>
                <TooltipComponent
                  text={tooltipsText[nameValue.key]}
                  icon={HelperIcon}
                />
                {project.businessModel[nameValue.key].types ? (
                  <>
                    <AddBMButton
                      style={{ marginRight: '5px' }}
                      onClick={() =>
                        addFields('types', project.businessModel[nameValue.key])
                      }
                    >
                      <AddIcon style={{ marginRight: '5px' }} />
                      Type
                    </AddBMButton>
                    <AddBMButton
                      style={{ marginRight: '5px', width: '150px' }}
                      onClick={() =>
                        addFields(
                          'fixedPricing',
                          project.businessModel[nameValue.key],
                        )
                      }
                    >
                      <AddIcon style={{ marginRight: '5px' }} />
                      Fixed pricing
                    </AddBMButton>
                    <AddBMButton
                      style={{ width: '180px' }}
                      onClick={() =>
                        addFields(
                          'dynamicPricing',
                          project.businessModel[nameValue.key],
                        )
                      }
                    >
                      <AddIcon style={{ marginRight: '5px' }} />
                      Dynamic pricing
                    </AddBMButton>
                  </>
                ) : (
                  <AddBMButton
                    onClick={() =>
                      addFields(
                        nameValue.key,
                        project.businessModel[nameValue.key],
                      )
                    }
                  >
                    <AddIcon style={{ marginRight: '5px' }} />
                    Field
                  </AddBMButton>
                )}
              </Box>
              <Grid container>
                {project.businessModel[nameValue.key].hasOwnProperty(
                  'dynamicPricing',
                ) ? (
                  Object.entries(project.businessModel[nameValue.key])?.map(
                    ([el, fields]) =>
                      fields.length > 0 ? (
                        <Grid container>
                          {fields?.map((element, i) => (
                            <Grid key={i} item xs={4}>
                              <Box position="relative" mb="10px">
                                <input
                                  style={{
                                    border: '2px solid #c7c7c7',
                                    borderRadius: '5px',
                                    height: '50px',
                                    width: '85%',
                                    marginTop: '10px',
                                  }}
                                  className="buisness-input"
                                  value={element}
                                  name={el}
                                  type="text"
                                  placeholder={getTitle(el) + ' ' + (i + 1)}
                                  onChange={(e) =>
                                    changeValues(e.target.value, i, el)
                                  }
                                />
                                {i > 0 ? (
                                  <DeleteBisModel
                                    style={{
                                      cursor: 'pointer',
                                      position: 'absolute',
                                      top: 0,
                                      left: '86%',
                                    }}
                                    onClick={() => RemoveFields(i, el)}
                                  />
                                ) : (
                                  <></>
                                )}
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      ) : (
                        <></>
                      ),
                  )
                ) : project.businessModel[nameValue.key].length > 0 ? (
                  project.businessModel[nameValue.key].map((item, i) => (
                    <Grid key={i} item xs={4}>
                      <Box position="relative" mb="10px">
                        <input
                          style={{
                            border: '2px solid #c7c7c7',
                            borderRadius: '5px',
                            height: '50px',
                            width: '85%',
                            colot: '#c7c7c7',
                            marginTop: '10px',
                          }}
                          className="buisness-input"
                          value={item}
                          name={nameValue.key}
                          type="text"
                          placeholder={
                            getTitle(nameValue.title) + ' ' + (i + 1)
                          }
                          onChange={(e) =>
                            changeValues(e.target.value, i, nameValue.key)
                          }
                        />
                        {i > 0 ? (
                          <DeleteBisModel
                            style={{
                              cursor: 'pointer',
                              position: 'absolute',
                              top: 0,
                              left: '86%',
                            }}
                            onClick={() => RemoveFields(i, nameValue.key)}
                          />
                        ) : (
                          <></>
                        )}
                      </Box>
                    </Grid>
                  ))
                ) : (
                  <></>
                )}
              </Grid>
            </div>
          ))}
        </Box>
        <SaveBMButton
          type="submit"
          onClick={() => {
            SendChenges();
          }}
          disabled={disabled}
        >
          Save
        </SaveBMButton>
      </Box>
    </Box>
  );
}
