// Common
import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Link as routerLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Components
import SessionContainer from './session_container';
import { Grid, Typography, Button, Link, Box } from '@material-ui/core';
import { Input } from '../common/input/index';
import { CheckBox } from '../common/checkbox/index';
import CountryCode from '../common/countryCode';
import TermsDoc from '../../docs/terms_conditions.docx';
import PocicyDoc from '../../docs/privacy_policy.docx';

// Actions
import { registerRequest } from '../../actions/user';

export default function SignUp() {
  const [user, setUser] = useState({
    name: '',
    phone: '',
    email: '',
    password: '',
    countryCode: '',
  });
  const [activeButton, setActiveButton] = useState(true);
  const [agreed_policy, setAgreedPolicy] = useState(false);
  const [sign_me, setSignMe] = useState(false);
  const errors = useSelector((state) => state.errors);
  const dispatch = useDispatch();

  useEffect(() => {
    let active = true;
    for (let i in user) {
      if (!!user[i] === false) {
        active = false;
        break;
      }
    }
    setActiveButton(active && agreed_policy && !Object.keys(errors).length);
  }, [agreed_policy, errors, user]);

  const handleChange = (event) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = useCallback(() => {
    dispatch(registerRequest({ ...user, role: 'owner', isConfirmed: false }));
  }, [dispatch, user]);

  const data = useMemo(() => {
    return [
      {
        label: 'Name',
        value: user.name,
        name: 'name',
        type: 'text',
        validation: true,
      },
      {
        label: 'Mobile number',
        value: user.phone,
        name: 'phone',
        type: 'text',
        validation: true,
      },
      {
        label: 'Email',
        value: user.email,
        name: 'email',
        type: 'text',
        validation: true,
      },
      {
        label: 'Password',
        value: user.password,
        name: 'password',
        type: 'password',
        validation: true,
      },
    ];
  }, [user]);

  return (
    <SessionContainer>
      <Grid item>
        <Typography variant="h1">Sign up for an account</Typography>
      </Grid>
      {data.map((item) =>
        item.name === 'phone' ? (
          <Grid item container spacing={1} key={item.label}>
            <Grid item xs={6}>
              <CountryCode
                isSignUp
                value={user.countryCode}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                label={item.label}
                name={item.name}
                type={item.type}
                value={item.value}
                onChange={handleChange}
                validation={item.validation}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid key={item.label} item>
            <Input
              label={item.label}
              name={item.name}
              type={item.type}
              value={item.value}
              onChange={handleChange}
              validation={item.validation}
            />
          </Grid>
        ),
      )}
      <Grid item>
        <CheckBox
          label={'Sign me up for Moneyflow newletter'}
          value={sign_me}
          onChange={setSignMe}
        />
        <Box mt="24px">
          <CheckBox
            label={
              <Typography variant="body1">
                {'I agree to the '}
                <Link target="_blank" href={TermsDoc} underline="hover">
                  Terms of Use
                </Link>
                {' and have read and understood thr '}
                <Link target="_blank" href={PocicyDoc} underline="hover">
                  Privacy police
                </Link>
              </Typography>
            }
            value={agreed_policy}
            onChange={setAgreedPolicy}
          />
        </Box>
      </Grid>
      <Grid item>
        <Button
          onClick={handleSubmit}
          size="large"
          color="primary"
          variant="contained"
          disabled={!activeButton}
        >
          Create account
        </Button>
        <Box mt="16px">
          <Typography variant="subtitle1">
            {'Already have an account? '}
            <Link underline="none" component={routerLink} to="/login">
              Log in
            </Link>
          </Typography>
        </Box>
      </Grid>
    </SessionContainer>
  );
}
