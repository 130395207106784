import { createMuiTheme } from '@material-ui/core';

export const details = createMuiTheme();

details.typography.h1 = {
  fontFamily: 'SFProDisplay-SemiBold',
  fontSize: '24px',
  marginTop: '2px',
  fontWeight: '600',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: '1.58',
  letterSpacing: 'normal',
};

details.typography.h2 = {
  fontFamily: 'SFProDisplay-Medium',
  fontSize: '16px',
  marginTop: '14px',
  fontWeight: '500',
};

details.typography.h3 = {
  fontFamily: 'SFProDisplay-Regular',
  fontSize: '14px',
  marginTop: '8px',
  lineHeight: '1.57',
  fontWeight: 'normal',
  letterSpacing: 'normal',
  fontStyle: 'normal',
};

details.palette.secondary.main = '#858585';

export const title = createMuiTheme();

title.typography.h1 = {
  fontSize: '28px',
  lineHeight: '40px',
  letterSpacing: '0.28px',
  fontFamily: 'SFProDisplay-Bold',
};

export const name = createMuiTheme({
  typography: {
    h1: {
      fontSize: '18px',
      margin: '24px 0 2px 0',
      fontFamily: 'SFProDisplay-SemiBold',
    },
  },
});

export const email = createMuiTheme({
  typography: {
    h1: {
      fontSize: '16px',
      fontFamily: 'SFProDisplay-Regular',
      lineHeight: '24px',
    },
  },
});

email.palette.secondary.main = '#858585';
