// Common
import { makeStyles } from '@material-ui/core/styles';

// Components
import {
  FormControl,
  Select as MaterialSelect,
  MenuItem,
  InputLabel,
} from '@material-ui/core';

// Styles
const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 366,
  },
}));

export function Select({ values, name, value, onChange, label }) {
  const classes = useStyles();
  return (
    <FormControl className={classes.formControl} variant="outlined">
      {!value && <InputLabel disabled={!!value}>{label}</InputLabel>}
      <MaterialSelect name={name} value={value} onChange={onChange}>
        {values.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.name}
          </MenuItem>
        ))}
      </MaterialSelect>
    </FormControl>
  );
}
