import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import InvestorsItem from './investorsItem';
import { investorsList } from '../../../actions/projects';
import MessageDialog from './messageDialog';
import { newChat } from '../../../actions/messages';

import '../index.scss';

export default function InvestorsList() {
  const [open, setOpen] = useState(false);
  const [investorId, setInvestorId] = useState('');
  const investors = useSelector((state) => state.projects.selected.investors);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    dispatch(investorsList(id));
  }, [id, dispatch]);

  const openDialog = (investor_id) => {
    console.log(investor_id);
    setInvestorId(investor_id);
    setOpen(true);
  };

  const sendMessage = (message) => {
    console.log(investorId);
    dispatch(newChat(investorId, id, message));
  };

  return (
    <div className="investors_container">
      {investors.map((item, index) => (
        <InvestorsItem
          key={index}
          item={item}
          openMessgaeDialog={() => openDialog(item._id)}
        />
      ))}
      <MessageDialog
        open={open}
        handleClose={() => setOpen(!open)}
        send={(message) => sendMessage(message)}
      />
    </div>
  );
}
